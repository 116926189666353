<div class="sidebar">
  <div class="logo-details mx-3 mt-4 mb-3">
    <router-link to="/" class="d-inline-block"> <!-- Change the route as needed -->
      <img :src="require('@/assets/images/logo-text.svg')" class="img-fluid ms-1" width="150" alt="profileImg">
    </router-link>

  </div>

  <ul class="nav-links">
    <li> 
      <router-link   to="/dashboard" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Home
        </a>
      </router-link> 
    </li>

    <li> 
      <router-link   to="/content_upload" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Content Upload
        </a>
      </router-link> 
    </li>

    <li> 
      <router-link   to="/inventory" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Inventory
        </a>
      </router-link> 
    </li>

    

    <li> 
      <router-link   to="/order" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Order
        </a>
      </router-link> 
    </li>

    <li> 
      <router-link   to="/add_user" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Team
        </a>
      </router-link> 
    </li>

    <li> 
      <router-link   to="/analytics" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Analytics
        </a>
      </router-link> 
    </li> 

    <li>
      <router-link   to="/" exact-active-class="active">
        <a class="nav-link text-dark " href="#"  @click="logout">
          <i class='bx bxs-dashboard'></i> 
          Logout
        </a>
      </router-link> 
    </li>
    <br>
  </ul> 
</div>
 