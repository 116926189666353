/* global $ */
import Swal from 'sweetalert2';
import Product_detail from '@/views/Customer_Management/Customer_Search_Products/ProductDetailView.vue';
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import EventBus from '@/eventBus.js';

export default {
  name: 'HomeNavbarView',
  components: {
    Home_navbar,
    Product_detail,
  },
  data() {
    return {
      video_url: '',
      product: null,
      filteredProducts: [],
      showDropdown: false,
      quantity: 1,
      selectedSize: null,
      sizes: [0],
      cart: []
    };
  },

  created() {
    // Example: Check user authentication status
    this.LoggedIn = !!localStorage.getItem('token'); // Adjust according to your logic
    this.fetchCartDetails();
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

  },
  beforeDestroy() {
    // Cleanup event listener
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
  },

  methods: {
  // Fetch the products from the API
  async fetchProducts(image_id) {
    const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_product/${image_id}`;

    const response = await fetch(FETCH_PRODUCT_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(error => {
      console.error('Error fetching products:', error);
    });

    if (!response) return; // Exit if there was an error in fetching

    const data = await response.json();
    this.product = data;
    this.filteredProducts = data;

    // Assuming the sizes are part of the first product
    if (data.length > 0 && data[0].sizes) {
      this.sizes = data[0].sizes; // Set sizes from the first product
    }
  },
  // Video loading logic
  async loadVideo() {
    const video_id = this.$route.params.video_id;
    
    if (video_id) {
        try {
            const LOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_video/${video_id}`;

            // Fetch the video data
            const response = await fetch(LOAD_VIDEO_URL, {
                headers: {
                },
            });
            const data = await response.json();
            const video_url = data.video_url;

            $('#video_source').attr('src', video_url);
            $('#video_player')[0].load();

        } catch (error) {
            console.error('Error loading video:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error Loading Video',
                text: 'There was an error loading the video. Please try again.',
            });
        }
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Missing Video ID',
            text: 'No video ID was provided. Please select a valid video.',
        });
    }
},

    // Selecting a result (for dropdown)
    selectResult(result) {
      if (result.type === 'product') {
        this.loadVideo(result.video_id); // Load the video associated with the selected product
      }
      this.showDropdown = false;
    },

    // Increase quantity
    increaseQuantity() {
      this.quantity++;
    },

    // Decrease quantity
    decreaseQuantity() {
      if (this.quantity > 0) {
        this.quantity--;
      }
    },

    // Select a size
    selectSize(size) {
      this.selectedSize = size.size;
    },

    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=None; Secure";
    },

    // Helper function to get a cookie
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    async addToCart(productId) {
      const quantity = this.quantity;
    
      if (this.isLoggedIn()) { // Function to check if the user is logged in
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          const response = await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity
            })
          });
    
          // Check for a successful response
          const data = await response.json();
          const product_name = data.cart_products[0].product_name;
          
          Swal.fire({
            title: "Success!",
            text: `${product_name} has been added to your cart!`,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            }).then(() =>{
            EventBus.emit('cart-updated', data.total_quantity);
        })
        } catch (error) {
          console.error('Error adding to cart:', error);
          
        }
      } else {
        // Handle the case for guest users
        const cart = JSON.parse(this.getCookie('cart')) || [];    
        const existingProduct = cart.find(item => item.id === productId);
        
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: this.product.product_id,
            product_name: this.product.product.product_name,
            quantity: quantity,
            price: this.product.price,
            brand: this.product.product.brand__brand_name,
            image: this.product.product.image_url,
            product_id: productId
          });
        }
        
        this.setCookie('cart', JSON.stringify(cart), 1);
        this.fetchCartDetails();

        Swal.fire({
          title: "Success!",
          text: `${this.product.product.product_name} has been added to your cart!`,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
        })
      }
    },
    
    // Function to check if the user is logged in
    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null; 
    },
    async fetchCartDetails() {
      const token = localStorage.getItem('token');
  
      if (token) {
        // User is logged in, fetch the cart from the server
        const CART_API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_cart_details/`;
        try {
          const response = await $.ajax({
            url: CART_API_URL,
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
    
          if (response) {
            if (response.cart_products.length > 0) {
            this.cartItems = response.cart_products;
                
            this.cartQuantity = response.total_quantity;
            this.cartTotal = parseFloat(response.total_price);
          } else {
              this.cartProducts = [];
              this.closeCart();
          }
          }
          EventBus.emit('cart-updated', this.cartQuantity);

        } catch (error) {
          console.error('Failed to fetch cart from the server:', error);
    
          const cartCookie = this.getCookie('cart');
          const cart = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];
    
          this.cartItems = cart;
          this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
          this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
        }
      } else {
        const cartCookie = this.getCookie('cart');
        const cart = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];
        this.cartItems = cart;
        
        this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
        this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      }
    },
  },

  mounted() {
    // Fetch products when component is mounted
    const image_id = this.$route.params.id; // Get image_id from route params
    if (image_id) {
      this.fetchProducts(image_id); // Pass image_id to fetchProducts
    }

    // Optionally load video if a specific product ID is present in the route
    const video_id = this.$route.params.video_id;

    if (video_id) {
      this.loadVideo(video_id);
    }
    this.fetchCartDetails();

  },
  watch: {
    '$route.params.id': {
      immediate: true, // Will fetch on initial load as well
      handler(newImageId) {
        if (newImageId) {
          this.fetchProducts(newImageId); // Refetch product when image_id changes
        }
      }
    }
  },
};
