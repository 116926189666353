import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue'

export default {
  name: "SearchProduct",
  components: {
    Customer_navbar,
    Home_navbar
    
  },
  data() {
    return {
      search_query: '',
      products: [],
      videos: [], // Make sure this is initialized as an empty array

      filteredProducts: [],
      showDropdown: false,
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;

        const response = await fetch(FETCH_PRODUCT_URL, {
            headers: {
              'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        this.products = data;
        this.filteredProducts = data; // Initialize with all products
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    filterProducts() {
      const query = this.search_query.toLowerCase();
      if (query) {
        var search = new RegExp(query, 'i');
        this.filteredProducts = this.products.filter((product) => {
          return (
            search.test(product.product.product_name) ||
            search.test(product.product.brand__brand_name) ||
            search.test(product.product.description)
          );
        });
       this.showDropdown = this.filteredProducts.length > 0; // Show dropdown if matches found
      } else {
        this.filteredProducts = this.products; // Reset to all products if no search query
        this.showDropdown = false; // Hide dropdown when there's no search query
      }
    },

  },
  mounted() {
    this.fetchProducts(); // Fetch all products on component mount
  },
  
  watch: {
    search_query(){
      this.filterProducts()
    }
  }
};