import View_brands from '@/views/Brand_Management/View_Brands/ViewBrands.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'

export default {
  name: 'ViewBrands',
  components: {
    View_brands,
    Home_navbar,
    Filter_product
  },
  data: () => ({ brands: [], allProducts: [], search_query: "",selectedBrand: null, brandProducts: [] }),

  computed: {
    filteredProducts() {
      const query = this.search_query.toLowerCase();
      const search = new RegExp(query, 'i');
      return this.allProducts.filter(product => search.test(product.product.product_name) 
        || search.test(product.product.brand__brand_name) 
        || search.test(product.product.description) 
        || search.test(product.product.category__category_name)
      );
    },

    filteredBrandProducts() {
      if (!this.selectedBrand) return [];
      return this.allProducts.filter(product => product.product.brand__brand_name === this.selectedBrand.brand_name);
    }
  },

  mounted() {
    this.fetchProducts();
    if (this.$route.params.brandId) {
        this.fetchBrandProducts(this.$route.params.brandId);
    }
  },

  methods: {
    update_search(event) {
      this.search_query = event.target.value;
    },
    filter_product_list(data) {
      this.allProducts = data;
    },
    async fetchProducts() {
      const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
      try {
        const response = await fetch(FETCH_PRODUCT_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        this.allProducts = data;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    selectBrand(brandId) {
        this.$router.push({ name: 'view-brands-products', params: { brandId } });
        this.fetchBrandProducts(brandId)
    },
    
      async fetchBrandProducts(brandId) {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_products_by_brand_api/`;
        const params = { brand_id: brandId };
    
        try {
          const response = await fetch(FETCH_PRODUCT_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          });
          const data = await response.json();    
          this.brandProducts = data;
          this.selectedBrand = { brand_name: data[0].product.brand__brand_name };
        } catch (error) {
          console.error('Error fetching brand products:', error);
        }
      },
      handleImageError(event) {
        console.error(`Image loading error: ${event.target.src}`, event);
      },
    }
  }