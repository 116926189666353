<Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />
    <div v-if="search_query == ''" class="brands-grid justify-content-center">
        <div 
        v-for="(brand, index) in availableBrands" 
        :key="index" 
        class="brand-card justify-content-center align-items-center d-flex">
        <router-link :to="{ name: 'view-brands-products', params: { brandId: brand.id } }"
        class="product-link"
        @click="$emit('select-brand', brand.id)"
    >
        <img 
            v-if="brand.logo" 
            :src="brand.logo" 
            :alt="brand.brand_name"
            class="img-logo bg-transparent"
            @load="handleImageLoad"
            @error="handleImageError"
        />
    </router-link>
        </div>
    </div>

  <div v-else>
  <div class="container">
    <div class="row">
        <div class="col-md-3">
            <Filter_product
            @filter-products="filter_product_list"
            />
        </div>

        <div class="col-md-9">
            <div class="items-found">{{ filteredProducts.length }} items found</div>

            <!-- Dropdown to show combined results -->
            <div v-if="showDropdown" class="dropdown-menu">
                <div
                    v-for="result in filteredProducts"
                    :key="result.id"
                    @click="selectResult(result)"
                    class="dropdown-item"
                >
                <span v-if="result.type === 'product'">{{ result.product_name }}</span>
                <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                <span v-else>Description: {{ result.description }}</span>
                </div>

            </div>
        
            <div class="product-grid">
                <div class="product-card" v-for="product in filteredProducts" :key="product.id">
                  <router-link 
                    :to="{ name: 'product_detail', params: { id: product.id, video_id: product.product.brand_video_id }}" 
                    class="product-link"
                  >
                    <!-- Product Image and Info -->
                    <input type="hidden" ref="image_id" :value="product.id">
                    <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
                    </div>
                    <div class="product-info">
                      <h3 class="product-title">{{ product.product.product_name }}</h3>
                      <p class="product-brand">By {{ product.product.brand__brand_name }}</p>
                      <p class="product-price">R{{ product.price }}</p>
                    </div>
                  </router-link>
            
                  <!-- Add to Cart and Heart Button -->
                  <div class="d-flex justify-content-center align-items-center">
                    <button class="action-btn" @click.stop="addToCart(product)">ADD TO CART</button>
                    <i class="fa-sharp fa-regular fa-heart"></i>
                  </div>
                </div>      
            </div>
        </div>
    </div>

</div>
</div>