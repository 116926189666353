import Wish_list from '@/views/Influencer_Management/Wishlist/AddWishView.vue'
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue'; 


import Swal from 'sweetalert2';
import $ from 'jquery'; 

export default {
  components: {
    Wish_list,
    Influencer_sidebar,
    Influencer_navbar,
  },
data() {
    return {
    // The block is necessary to define and return the data object in Vue
    wishlistProducts: [],
    form: { first_name: '', last_name: '', email: '', phone_number: '', bio: '', profileImage:'' },
    videos: [],
    selectedProducts: [],
    brand_videos: [],
    image_files: [],
    hoveredVideo: null,
    currentVideoUrl: '',
    currentVideoTitle: '',
    currentVideoId: null,
    images: [],
    visibleCards: 6,
    currentPosition: 0,
    showDetails: [],
    };
  },
  methods: {
    async loadWishlistProducts() {  // Renamed method
      try {
        const token = localStorage.getItem('token');
        const LOAD_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/view_wishlist/`;

        const response = await fetch(LOAD_WISHLIST_URL, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();  // Parse the JSON response
          this.wishlistProducts = data;
        } else {
          console.error('Failed to load wishlist products:', response.statusText);
        }

      } catch (error) {
        console.error('Error loading wishlist products:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error Loading Wishlist',
          text: 'There was an error loading your wishlist. Please try again.',
        });
      }
    },
    async removeFromWishlist(product_id) {
      try {

        const token = localStorage.getItem('token');
        const DELETE_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/delete_wishlist/${product_id}`;

        const response = await $.ajax({
          url: DELETE_PRODUCT_URL,
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        if (response) {
          Swal.fire(
            'Removed!',
            'The product has been removed from wishlist.',
            'success'
          ).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Removal failed');
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Deletion Failed',
          text: 'Failed to remove product. Please try again.',
        });
      }
      },
      async fetchBrandVideos() {
        try {
          const GET_ALL__BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
          const response = await fetch(GET_ALL__BRAND_VIDEOS_URL, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const data = await response.json();
          this.brand_videos = data;
          this.loadImages();
           
        } catch (error) {
          console.error('Error fetching videos:', error);
        }
      },
  
      async fetchVideos() {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_content/`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch videos');
          }
  
          const data = await response.json();
          this.videos = data;
        } catch (error) {
          console.error('Error fetching videos:', error);
        }
      },
},
  mounted() {
    this.loadWishlistProducts();
    this.fetchVideos();
    this.fetchBrandVideos();
  }
};