<Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />

<div class="container mt-4">
  <div class="row g-4">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="col-12 col-md-6 mb-4"
    >
      <div class="card border-0">
        <div class="profile-info d-flex align-items-center mb-2">
          <img
            :src="item.image"
            class="rounded-circle me-2 profile-pic"
            alt="Profile"
          />
          <div class="profile-details">
            <RouterLink
              :to="{  name: 'view_Influencer_Profile', params: { influencerId: item.user.id } }"
              class="text-decoration-none text-dark"
            >
              <h6 class="mb-0">{{ item.user.first_name }} {{ item.user.last_name }}</h6>
            </RouterLink>
          </div>
        </div>

        <!-- Video section -->
        <div class="row g-3">
          <div
            v-for="(video, videoIndex) in item.videos"
            :key="videoIndex"
            class="col-12 col-md-4"
          >
            <video
              :src="video.video_url"
              class="view-video rounded"
            ></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>