/* global $ */
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';

export default {
  name: 'InventoryView',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      dataTable: null,
      images: []
    };
  },
  mounted() {
    this.fetchImages();
  },
  methods: {
    initializeDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#content_table')) {
          $('#content_table').DataTable().destroy(); // Destroy previous instance
        }
        this.dataTable = $('#content_table').DataTable({
          pageLength: 10,
          lengthChange: false,
          searching: true,
          ordering: true,
          paging: true,
          info: true,
          autoWidth: false,
        
        });
      });
    },
    async fetchImages() {
      try {
        const token = localStorage.getItem('token');
        const FETCH_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_inventory/`;
    
        // Perform the GET request
        const response = await $.ajax({
          url: FETCH_IMAGES_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        });
    
        // Directly use the response as it's already JSON-parsed
        this.images = response;
    
        // Initialize DataTable after the images are rendered
        this.$nextTick(() => {
          this.initializeDataTable();
        });
    
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    },    
  
    async deleteImage(image_id) {
      const token = localStorage.getItem('token');
      
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E39356',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting Image',
            text: 'Please wait while the image is being deleted...',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
    
          try {
            // Fix the double slashes in the URL
            const DELETE_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/delete_image/${image_id}`;
    
            // Perform the DELETE request
            const response = await $.ajax({
              url: DELETE_IMAGES_URL,
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${token}`
              }
            });
    
            // Since jQuery's $.ajax doesn't return `response.ok`, check the response directly
            if (response) {
              Swal.fire(
                'Deleted!',
                'The image has been deleted.',
                'success'
              ).then(() => {
                window.location.reload();  // Reload the page after deletion
              });
            } else {
              throw new Error('Deletion failed');
            }
    
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Deletion Failed',
              text: 'Failed to delete image. Please try again.',
            });
          }
        }
      });
    },
    
  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  },
  }
};
