<div class="sidebar">
  <div class="logo-details mx-3 mt-4 mb-3">
    
    <router-link to="/Customer_dashboard" class="d-inline-block"> 
      <img :src="require('@/assets/images/logo-text.svg')" class="img-fluid ms-1" width="150" alt="profileImg">
    </router-link>
  </div>

  <ul class="nav-links">
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Profile</span>
    </a> 
  
    <li>
      <div class="iocn-link">
        <a href="#">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Profile</span>
        </a>
        <i class='bx bxs-chevron-down arrow'></i>
      </div>
      <ul class="sub-menu">
        <li><a href=" ">Person Details</a></li>
        <li><a href=" ">Delivery Address</a></li> 
        <li><a href=" ">Security</a></li> 
      </ul>
    </li>

    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Orders</span>
    </a> 
  
    <li>
      <div class="iocn-link">
        <a href="#">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Orders</span>
        </a>
        <i class='bx bxs-chevron-down arrow'></i>
      </div>
      <ul class="sub-menu">
        <li><a href=" "> Orders</a></li>
        <li><a href=" ">Invoices</a></li> 
        <li><a href=" ">Return</a></li> 
      </ul>
    </li>

    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Payment</span>
    </a> 
  
    <li>
      <div class="iocn-link">
        <a href="#">
          <i class="bx bx-loader-circle"></i>
          <span class="link-name">Orders</span>
        </a>
        <i class='bx bxs-chevron-down arrow'></i>
      </div>
      <ul class="sub-menu">
          <li><a href=" "> Payment Details</a></li>
          <li><a href=" ">Refunds</a></li>  
      </ul>
    </li> 

    <li>
      <router-link   to="/" exact-active-class="active">
        <a class="nav-link text-dark " href="#">
          <i class='bx bxs-dashboard'></i> 
          Logout
        </a>
      </router-link> 
    </li>
    <br>
  </ul> 
</div>
 