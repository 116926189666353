<div class="container-fluid  mt-2">
  <Home_navbar/>
</div>
<div class="container-fluid  mt-2">
<div class=" video-containers  p-3 rounded-12">
    <video  id="video_player" autoplay muted loop >
      <source  id="video_source" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="fade-overlay"></div>

</div>

<div class="full-contents bg-white-50" v-if="product">
    <div class="image-cards h-50" >
      <img :src="product.product.image_url" alt="Product Image" class="product-image">
    </div>
    <div class="product-details font" >      
      <h1>{{ product.product.product_name }}</h1>
      <p>Product by: <span>{{ product.product.brand__brand_name }}</span></p>
      <p>R{{ product.price }}</p>

      <div class="separator"></div>

      <div class="quantity-selector">
        <label class="fw-bold fs-6">Quantity</label>
        <button class="quantity-btn ms-2" @click="decreaseQuantity">-</button>
        <span class="quantity-number">{{ quantity }}</span>
        <button class="quantity-btn " @click="increaseQuantity">+</button>
      </div>
      <div class="separator"></div>

      <!-- Color Selector -->
      <div class="color-selector">
        <label class="fw-bold fs-6">Color</label>
        <div class="">{{product.color}}</div>
      </div>

      <div class="separator"></div>

      <!-- Size Selector -->
      <h5 class="fw-bold">Size:</h5>
          <div class="d-flex mb-3">
              <div class="size-option me-2" 
                v-for="size in product.sizes" :key="size" 
                :class="{ active: selectedSize === size.size }"
                @click="selectSize(size)"
              >                
                {{ size.size }}
              </div>
          </div> 
      <div class="separator"></div>
      <p class="stock-info">Only {{product.quantity}} left - don't miss out.</p>

      <!-- Buttons -->
      <div class="action-buttons justify-content-center align-content-center d-flex">
        <button class="add-to-cart bg-warning rounded-12" @click="addToCart(product.id)"> <i class="fa-solid fa-cart-shopping wishlist-icon"></i> Add to Cart
        </button>
        <button class=" btn btn-sm btn-  "> <i class="fa-sharp fa-regular fa-heart wishlist-icon"></i>Add to Wishlist</button>
        
      </div>
    </div>
  </div>
</div>
  
  