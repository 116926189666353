import { ref, onMounted, watch, nextTick, computed} from 'vue';
import jQuery from 'jquery';
import { useRouter } from 'vue-router'; 
import axios from 'axios';
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import View_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import Swal from 'sweetalert2';
import EventBus from '@/eventBus.js';
import videojs from 'video.js';

export default {
  name: 'HomeNavbarView',
  components: {
    Home_navbar,
    View_product,
    Filter_product
  },
  data(){
    return {
      selected_department_name: '',
      selected_category_name: '',
      FETCH_VIDEOS_URL:'',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,

    }
  },
  created() {
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });
  },
  beforeDestroy() {
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.off('roleChanged');
  },

  
  methods:{

    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },

    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=None; Secure";
    },

   
    async fetchCartDetails() {
      const cartCookie = this.getCookie('cart');
      const cart = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];
    
      for (const item of cart) {
      
          const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.product_id}/`;
          const response = await fetch(GET_IMAGE_URL);
          
          if (!response.ok) {
            throw new Error('Failed to fetch image URL');
          }
          const imageData = await response.json();
          item.image = imageData;
      }
    
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    }, 

    async addToCart(product) {
      const quantity = 1; // Use the selected quantity
      const productId = product.id;

      if (this.isLoggedIn()) { // Function to check if the user is logged in
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          const response = await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity
            })
          });

          // Check for a successful response
          const data = await response.json();
          const product_name = data.cart_products[0].product_name;
          Swal.fire({
            title: "Success!",
            text: `${product_name} has been added to your cart!`,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          }).then(() =>{
            EventBus.emit('cart-updated', data.total_quantity);
          })
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        // Handle the case for guest users
        const cart = JSON.parse(this.getCookie('cart')) || [];

        const existingProduct = cart.find(item => item.id === productId);
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: productId,
            product_name: product.product.product_name,
            quantity: quantity,
            price: product.price,
            brand: product.product.brand__brand_name,
            image: product.product.image_url,
            product_id: product.product_id
          });
        }
        this.setCookie('cart', JSON.stringify(cart), 1);
        this.fetchCartDetails();
        Swal.fire({
          title: "Success!",
          text: `${product.product.product_name} has been added to your cart!`,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
        })
      }
    },


    async fetchReelData() {
      if (this.isFetching || this.allDataLoaded) return;
 
      try {
        this.isFetching = true;
        this.FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
        const response = await fetch(`${this.FETCH_VIDEOS_URL}?page=${this.page}&limit=${this.limit}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
 
        if (data.length === 0) {
          this.allDataLoaded = true;
        } else {
          this.reels.push(...data);
          this.page++;

          // Initialize Video.js and Observers after new reels are rendered
          this.$nextTick(() => {
            this.initializeVideoJS();
            this.initializeVideoObserver();
          });
        }
      } catch (error) {
        console.error("Error fetching reel data:", error);
      } finally {
        this.isFetching = false;
      }
    },


    
    initializeVideoJS() {
      this.reels.forEach((_, index) => {
        const videoEl = this.$refs['videoPlayer_' + index];
    
        // Check if videoEl is a valid DOM element
        if (videoEl instanceof HTMLElement && !this.players[index]) {
          const player = videojs(videoEl.querySelector('video'), {
            autoplay: true,
            muted: true,
            controls: false,
            preload: "auto",
            loop: true,
            html5: {

              vhs: {
  
                overrideNative: !videojs.browser.IS_SAFARI,
  
              },
  
              nativeVideoTracks: false,
  
              nativeAudioTracks: false,
  
              nativeTextTracks: false,
  
            },
          });
          
          this.players[index] = player;
        } else {
          console.error(`videoEl is not a valid HTML element or is undefined for index ${index}`);
        }
      });
    },

    initializeInfiniteScroll() {
      this.$nextTick(() => {
        const sentinel = this.$refs.scrollSentinel;
        if (sentinel) {
          const observer = new IntersectionObserver(
            (entries) => {
              if (entries[0].isIntersecting && !this.isFetching) {
                this.fetchReelData();
              }
            },
            {
              root: null,
              rootMargin: "100px",
              threshold: 0,
            }
          );
          observer.observe(sentinel);
        }
      });
    },

    initializeVideoObserver() {
      const options = {

        root: null,
  
        rootMargin: '0px',
  
        threshold: 0.75,
  
      };
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const videoIndex = parseInt(entry.target.dataset.index, 10);
            if (videoIndex !== -1 && this.players[videoIndex]) {
              if (entry.isIntersecting) {
                this.setCurrentPlaying(videoIndex);
                const playPromise = this.players[videoIndex].play();

                if (playPromise !== undefined) {

                  playPromise.catch((error) => {

                    console.log('Autoplay prevented:', error);

                  });

                }
              } else {
                this.players[videoIndex].pause();
              }
            }
          });
        },options);
 
        this.reels.forEach((_, index) => {
    
          const videoEl = this.$refs['videoPlayer_' + index];
    
          if (videoEl) {
    
            const videoElement = videoEl.querySelector('video');
    
            videoElement.dataset.index = index;
    
            observer.observe(videoElement);
    
          }
    
        });
        
    },

    openFullScreen(video, index) {
      // Store original reels if not already stored
      if (!this.originalReels.length) {
        this.originalReels = [...this.reels];
      }
    
      if (!this.originalReels || !this.originalReels[index]) {
        console.error("Invalid index or reels not loaded");
        return;
      }
    
      // Set fullscreen reels
      this.fullScreenReels = [this.originalReels[index]];
      this.isFullScreenMode = true;
    
      this.$nextTick(() => {
        // Find the video element reference
        const videoEl = this.$refs[`videoPlayer_${index}`];
        if (videoEl) {
          const element = Array.isArray(videoEl) ? videoEl[0] : videoEl;
          const videoElement = element.querySelector('video');
          if (videoElement) {
            // Initialize the video player if it hasn't been done before
            if (!this.players[index]) {
              const player = videojs(videoElement, {
                autoplay: true,
                muted: true,
                controls: true,
                preload: "auto",
                loop: true,
              });
              this.players[index] = player;
            }
    
            // Scroll to the video element
            videoElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }
      });
    },
  

    exitFullScreen() {
      // Cleanup current video player
      if (this.players[0]) {
        this.players[0].dispose();
        delete this.players[0];
      }
  
      // Reset states
      this.isFullScreenMode = false;
      this.currentPlayingIndex = null;
      
      // Restore original reels
      if (this.originalReels.length) {
        this.reels = [...this.originalReels];
      }
      
      // Clear fullscreen reels
      this.fullScreenReels = [];
    },

    setCurrentPlaying(index) {
      if (this.currentPlayingIndex === index) return;
  
      // Pause current playing video if exists
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
  
      this.currentPlayingIndex = index;

      const videoEl = this.$refs['videoPlayer_' + index];
      if (videoEl) {
        videoEl.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },

    beforeDestroy() {
    // Cleanup all video players
      Object.values(this.players).forEach(player => {
        if (player && typeof player.dispose === 'function') {
          player.dispose();
        }
      });
      
      this.players = {};
      this.originalReels = [];
      this.fullScreenReels = [];
    },

    enableAutoplay() {

      this.userInteracted = true;
  
      if (this.players[this.currentPlayingIndex]) {
  
        this.players[this.currentPlayingIndex].play();
  
      }
  
    },
  
  },
  mounted() {
    this.fetchReelData();
    this.initializeInfiniteScroll();
  },


  setup() {
    const router = useRouter();
    const selectedRole = ref(localStorage.getItem('selectedRole') || 'customer');
    const search_query = ref('');
    const products = ref([]);
    const filteredProducts = ref([]);
    const allProducts = ref([]);
    const brand_videos = ref([]);
    const image_files = ref([]);
    const influencer_videos = ref([]);
    const showDropdown = ref(false);
    const showDetails = ref([]);
    const currentPosition = ref(0);
    const visibleCards = ref(6);
    const hoveredVideo = ref(null);  // Define hoveredVideo
    const videoRefs = ref([]);
    const images = ref([]);
    const currentVideoId = ref('');
    const images_data = ref([]);
    const video_products = ref([])
    const heroVideo = ref(null);
    const selectedDepartments = ref([]);

    const filter_product_list = (data) =>{
      allProducts.value = data;
      filteredProducts.value = data;
    };
    const currentIndex = ref(0);
    const isFullScreen = ref(false); // Fullscreen toggle
    const selectedVideo = ref(null);
    const currentCollection = ref([]);
    const selected_collection_type = ref('');

    const updateRole = (role) => {
      selectedRole.value = role;
      localStorage.setItem('selectedRole', role);
    };

    const onImageClick = (image_id) => {
      // Fetch products before navigating to the new route
      fetchProducts().then(() => {
        // Construct the URL for the product route
        const product_url = router.resolve({ name: 'product', params: { id: image_id } }).href;

        // Change the window location to the new URL and refresh the page
        window.location.href = product_url;
        window.location.reload(); // This will refresh the page
      });
    };
    const fetchProducts = async () => {
      try {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;

        const response = await fetch(FETCH_PRODUCT_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        products.value = data;
        allProducts .value = data;
        filteredProducts.value = data; // Initialize with all products
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const filterProducts = () => {
      const query = search_query.value.toLowerCase();
      filteredProducts.value = [...allProducts.value];
      if (query) {
          const search = new RegExp(query, 'i');
          filteredProducts.value = filteredProducts.value.filter((product) => {
              const match = (
                  search.test(product.product.product_name) ||
                  search.test(product.product.brand__brand_name) ||
                  search.test(product.product.description) ||
                  search.test(product.product.category__category_name)
              );
              return match;
          });
      }
  };

    const initializeShowDetails = () => {
      showDetails.value = new Array(brand_videos.value.length).fill(false);
    };

    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = jQuery('.overlay').eq(index);
      if (!showDetails.value[index] && isHovering) {
        overlay.addClass('bg-dark');
      } else {
        overlay.removeClass('bg-dark');
      }
    };

    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };

    const fetchBrandVideos = async () => {
      try {
        const GET_ALL_BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
    
        const response = await fetch(GET_ALL_BRAND_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        const data = await response.json();
    
        // If the data structure has videos nested, adjust this line:
        brand_videos.value = data.results || data; // Adjust based on actual response structure
    
        loadImages();
        if (brand_videos.value.length > 0) {
          loadImagesForVideos();
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    const loadImages = () => {
    
      if (brand_videos.value && brand_videos.value.length > 0) {
        const video_id = brand_videos.value[0].id;
    
        const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video_id}`;
    
        axios.get(VIEW_IMAGE_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
    
          image_files.value = response.data; 
        })
        .catch(error => {
          console.error('Error loading images:', error);
        });
      } else {
        console.error('No brand videos available or brand_videos is empty.');
      }
    };


    const setCurrentVideo = async (videoId) => {
      currentVideoId.value = videoId; // Set the current video ID
    
      // Ensure images are reset before loading new ones
      await loadVideoImages(videoId);
    };
  
    const loadVideoImages = async (videoId) => {
      const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${videoId}`;
      
      try {
        const response = await fetch(VIEW_IMAGE_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
    
        const data = await response.json();
        images.value = data;
    
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };
    
    
    const fetchInfluencersVideos = async () => {
      try {
        const GET_ALL_INFLUENCER_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
        const response = await fetch(GET_ALL_INFLUENCER_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        influencer_videos.value = data;
      } catch (error) {
        console.error('Error fetching influencers videos:', error);
      }
    };
    
    const scrollCards = (direction) => {
      const maxScroll = brand_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };

    const scrollerCards = (direction) => {
      const maxScroll = influencer_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    const showPopUp = (index) => {
      this.hoveredVideo = index;
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.play().catch(error => console.error("Error playing video:", error));
      }
    };
    
    const hidePopUp = (index) => {
      this.hoveredVideo = null;
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };

    const pauseVideo = (index) => {
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };
    const loadImagesForVideos = ()  => {
      if (!brand_videos.value || brand_videos.value.length === 0) {
        console.error('No videos available to load images for.');
        return;
      }

      // Loop through each video and fetch images for that video
      for (let video of brand_videos.value) {
        try {
          const VIEW_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video.id}`;
 
          axios.get(VIEW_PRODUCTS_URL, {
              headers: {
                  'Content-Type': 'application/json'
              }
          })
          .then(response => {
            video.video_products = response.data; // Add images to the video object

          })
        } catch (error) {
          console.error(`Error fetching images for video ${video.id}:`, error);
        }
      }
    };
    function getProductImages(videoId) {
      return images.value[videoId] || [];
    }
    const displayedVideos = computed(() => {
      const totalVideos = currentCollection.value.length;
  
      if (totalVideos === 0) return [];
  
      const previousIndex = currentPosition.value > 0 ? currentPosition.value - 1 : null;
      const nextIndex = currentPosition.value < totalVideos - 1 ? currentPosition.value + 1 : null;
  
      const videosWithImages = [
          previousIndex !== null
              ? {
                  ...currentCollection.value[previousIndex],
                  product_images: getProductImages(currentCollection.value[previousIndex].id),
              }
              : null,
          {
              ...currentCollection.value[currentPosition.value],
              product_images: getProductImages(currentCollection.value[currentPosition.value].id),
          },
          nextIndex !== null
              ? {
                  ...currentCollection.value[nextIndex],
                  product_images: getProductImages(currentCollection.value[nextIndex].id),
              }
              : null,
      ].filter(Boolean); // Remove null entries
  
      return videosWithImages;
  });

    
    const nextVideo = async () => {

      currentPosition.value = (currentPosition.value + 1) % currentCollection.value.length; // Move to the next index
      selectedVideo.value = currentCollection.value[currentPosition.value]; // Update the selected video
      await loadVideoImages(selectedVideo.value.id); // Load the images for the new video
    };
    
    const previousVideo = async () => {
      currentPosition.value = (currentPosition.value - 1 + currentCollection.value.length) % currentCollection.value.length; // Move to the previous index
      selectedVideo.value = currentCollection.value[currentPosition.value]; // Update the selected video
      await loadVideoImages(selectedVideo.value.id); // Load the images for the new video
    };
  

    // let lastScrollTop = 0;
    const scrollVideo = (event) => {
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight - event.target.clientHeight;
    
      // Find the current index in displayedVideos
      const currentIndex = displayedVideos.value.findIndex(v => v.id === selectedVideo.value.id);
    
      if (scrollTop <= 0 && currentIndex > 0) {
        // Scroll up to previous video
        currentPosition.value = (currentPosition.value - 1 + currentCollection.value.length) % currentCollection.value.length;
        selectedVideo.value = currentCollection.value[currentPosition.value];
        loadVideoImages(selectedVideo.value.id); // Ensure images are loaded
      } else if (scrollTop >= scrollHeight && currentIndex < displayedVideos.value.length - 1) {
        // Scroll down to next video
        currentPosition.value = (currentPosition.value + 1) % currentCollection.value.length;
        selectedVideo.value = currentCollection.value[currentPosition.value];
        loadVideoImages(selectedVideo.value.id); // Ensure images are loaded
      }
    };

    onMounted(() => {
      watch(() => influencer_videos.value[0], (new_video) => {
        if (new_video && new_video.video_url) {
          nextTick(() => {
            const video_element = heroVideo.value;
    
            if (video_element) {
              const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                  if (entry.isIntersecting) {
                    video_element.play().catch(error => console.error("Error playing video:", error));
                  } else {
                    video_element.pause(); 
                  }
                });
              }, {
                threshold: 0.25,
              });
    
              observer.observe(video_element);
            } else {
              console.error("heroVideo ref is null. Ensure the video element is rendered correctly.");
            }
          });
        }
      }, { immediate: true });
  
      updateVisibleCards();
      jQuery(window).on('resize', updateVisibleCards);
      const $firstNavbar = jQuery('.first-navbar');
      const firstNavbarOffset = $firstNavbar.offset().top;
    
      jQuery(window).on('scroll', function() {
        if (jQuery(window).scrollTop() >= firstNavbarOffset) {
          $firstNavbar.addClass('position-fixed');
        } else {
          $firstNavbar.removeClass('position-fixed');
        }
      });
    
    
      fetchProducts();
      fetchBrandVideos();
      fetchInfluencersVideos();
      loadImages();
      pauseVideo();
      loadImagesForVideos();

    });

    watch(
      () => isFullScreen.value,
      (new_value) => {
        if (new_value) {
          nextTick(() => {
            const video_container = document.querySelector('.video-cards');
            if (video_container) {
              video_container.addEventListener('scroll', scrollVideo);
            } else {
              console.error(".video-cards element not found. Ensure it's rendered in the DOM.");
            }
          });
        }
      }
    );
    watch(search_query, filterProducts);
    watch(brand_videos, initializeShowDetails);

    return {
      selectedRole,
      search_query,
      products,
      filteredProducts,
      brand_videos,
      influencer_videos,
      showDropdown,
      showDetails,
      currentPosition,
      visibleCards,
      hoveredVideo,
      image_files,
      videoRefs,
      images,
      currentVideoId,
      images_data,
      video_products,
      heroVideo,
      selectedDepartments,
      currentIndex,
      selectedVideo,
      isFullScreen,
      selected_collection_type,
      displayedVideos,
      updateRole,
      fetchProducts,
      filterProducts,
      scrollCards,
      showPopUp,
      hidePopUp,
      toggleDetails,
      toggleOverlay,
      setCurrentVideo,
      pauseVideo,
      loadVideoImages,
      onImageClick,
      filter_product_list,
      nextVideo,
      previousVideo,
      scrollerCards,
      scrollVideo,
    };
  },
 
};
