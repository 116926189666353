
<div class="container-fluid ">
  <Influencer_navbar v-model:search_input="search_query"/>
  <Products_navbar />
  <br>
  <div v-if="!search_query">
  <div v-if="brand_videos.length > 0">
    <div class="hero">
      <div class="video-container  border-0">
        <div class="fade-overlay"></div>        
            <video :src="brand_videos[0].video_url" ref="heroVideo" autoplay >
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="hero-content text-start text-light">
              <div class="d-flex align-content-center d-none">
                  <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
                  <h4 class="ms-2 mt-n1">Khiosk</h4>
              </div>  
              <p></p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="hero-cards hero-product">
              <div class="bg-transparent">
                <div class="scroller-container"> 
                  <div class="sub-scroller" ref="scroller">
                    <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                    <div class="logo-description-container  "> 
                      <div class="justify-content-end">
                        <router-link :to="{name: 'product', params: {id: image.id}}" v-for="(image, index) in image_files" :key="index" class="card h-100">
                          <input type="hidden" ref="image_id" :value="image.id">
                          <img :src="image.product.image_url" 
                          class="rounded-12 p-1  h-130 mw-90 card-img-top" 
                          @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  
                          />
                          <div class="card-body text-center"> 
                            <p class="card-text description text-white"> {{ image.product.product_name }}</p>
                            <p class="card-text description text-white"> {{ image.product.brand__brand_name  }}</p>
                            <h5 class="card-title text-warning fw-bold">R{{ image.price }}</h5>
                          </div>
                        </router-link> 
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <br>
  
      <!-- New Collection SECTION -->
      <h3 class="mt-4 fw-bold">New Collection</h3>
      <div class="px-5 d-flow-root subvideo-content mb-5  ">
          <div class="scroller-container">
              <div class="scroller">
                  <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
                      <div v-for="(video, index) in brand_videos" :key="index" class="card">

                          <div class="product_modal" >
                              <div class="view-product-list" >
                                  <div class="card-product-list" >
                                      <button class="icon-button fs-6 left-10  text-muted rounded-pill ">
                                          <i class="bx bx-shopping-bag"></i>
                                      </button>
                                  

                                      <div @click="openFullScreen(video.id, index)">
                                          <img v-if="hoveredVideo !== index" :src="video.cover_image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" class="video-cover rounded-12 img-fluid h-250 object-fit-cover"/>
                                          <video v-if="hoveredVideo === index" :src="video.video_url" class="rounded-12 video-preview" @mouseenter="showPopUp(index)" @mouseleave="hidePopUp(index)">
                                          </video>
                                      </div>
                                  
                                      <div class="progress-bar"></div>
                                      <div class="circle-images overflow-auto" >
                                          <router-link :to="{name: 'product', params: {id: image.id}}" v-for="(image, index) in video.video_products" :key="index">  
                                              <input type="hidden" ref="image_id" :value="image.id">
                                              <img :src="image.product.image_url" class="circle-image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  >  
                                          </router-link>
                                      </div>
                                  </div>
                  
                              </div>
                          </div>
                          <div class="  text-start mt-2 ms-1">
                              <h6 class="fw-bold">{{ video.description }}</h6>
                              <p class="text-muted">{{ video.sub_description }}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- Scroll Buttons -->
              <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
              <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
              </div>
          </div>

      <!-- Fullscreen Video Modal -->
      <div :class="['full-screen-video', { show: isFullScreen }]" v-if="isFullScreen">
          <div class="video-wrapper">
              <button class="close-btn" @click="closeFullScreen">
                  <i class="bx bx-x"></i>
              </button>

              <!-- Display current and next two videos -->
              <div class=" video-cards">
                  <div v-for="(video, index) in displayedVideos" :key="video.id" :class="['video-card', { 'active-video': selectedVideo.id === video.id, 'inactive-video': selectedVideo.id !== video.id }]">
                      <div class="card-body text-center">
                          <h5 class="card-title text-white">{{ video.description }}</h5>
                      </div>
                      <div class="card d-flex justify-content-between mt-3">
                          <video  :src="video.video_url" :autoplay="selectedVideo.id === video.id" class="object-fit-cover " muted loop>
                              Your browser does not support the video tag.
                          </video>
                      </div>
                     
                      <!-- Product Images for Each Video -->
                      <div class="circle-images overflow-auto d-flex justify-content-center gap-3 mt-2">
                          <router-link v-for="(image, imgIndex) in video.video_products" :key="imgIndex" :to="{ name: 'product', params: { id: image.id } }" @click.prevent="onImageClick(image.id)">
                              <img :src="image.product.image_url" class="modal-circle-image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />
                          </router-link>
                      </div>
                  </div>
              </div>

              <button class="nav-arrow left video-overlay left-overlay" @click="previousVideo">
                <i class="bx bx-chevron-left fs-3"></i>
            </button>
            <button class="nav-arrow right video-overlay right-overlay" @click="nextVideo">
                <i class="bx bx-chevron-right fs-3"></i>
            </button>
          </div>
      </div>
     
  
      <!-- PLAY Modal -->
      <div class="modal fade hero-play-modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
          <div class="modal-content"> 
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body card border-0"> 
              <div class="video-container  border-0">  
                <video :src="brand_videos[0].video_url" autoplay muted loop controls>
                    Your browser does not support the video tag.
                </video>
                <div class="col-md-6">
                  <div class="hero-cards hero-product">
                    <div class="bg-transparent">
                      <div class="scroller-container"> 
                        <div class="sub-scroller" ref="scroller">
                          <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                          <div class="logo-description-container  "> 
                            <div class="justify-content-end">
                              <div :to="{name: 'product', params: {id: image.id}}" v-for="(image, index) in image_files" :key="index" class="card h-100">
                                <input type="hidden" ref="image_id" :value="image.id">
                                <img :src="image.product.image_url" 
                                class="rounded-12 p-1 bg-white h-150 img-fluid card-img-top" 
                                @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  
                                >
                                <div class="card-body text-start"> 
                                  <p class="card-text description text-white"> {{ image.product.product_name }}</p>
                                  <p class="card-text description text-white"> {{ image.product.brand__brand_name  }}</p>
                                  <h5 class="card-title text-warning fw-bold">R{{ image.price }}</h5>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div> 


<div class="container home-product">
  <h1 class="text-center my-4 ms-4 text-dark fw-bold">Latest Offers This Month</h1>
   
  <div class="row">
    <div class="card card-large">
        <span class="card-text text-dark">New Collection</span>
    </div>
    <div class="card card-small">
        <span class="card-text">Trends</span>
    </div>
  </div>

  <div class="row">
    <div class="card card-image home-band">
          <span class="card-text text-white">Brands  </span>
    </div>
    <div class="card card-image home-influencer">
        
        <span class="card-text text-white">Influencer  </span>
    </div>
    <div class="card card-image home-shop"> 
        <img :src="require('@/assets/images/logo-icon-black.png')" alt="Kyosk Logo" class="w-25 h-25 rounded-12 d-none">

        <span class="card-text text-white">Shop Now </span>
    </div>
  </div>
</div>
</div>
<div v-else>
  <div class="container">
    <div class="row">
        <div class="col-md-3">
            <Filter_product
            @filter-products="filter_product_list"
            />
        </div>

        <div class="col-md-9">
            <div class="items-found">{{ filteredProducts.length }} items found</div>

            <!-- Dropdown to show combined results -->
            <div v-if="showDropdown" class="dropdown-menu">
                <div
                    v-for="result in filteredProducts"
                    :key="result.id"
                    @click="selectResult(result)"
                    class="dropdown-item"
                >
                <span v-if="result.type === 'product'">{{ result.product_name }}</span>
                <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                <span v-else>Description: {{ result.description }}</span>
                </div>
            </div>
        
            <div class="product-grid">
                <router-link :to="{name: 'product_detail', params: {id: product.id, video_id: product.product.brand_video_id }}" class="product-card" v-for="product in filteredProducts" :key="product.id">
                    <input type="hidden" ref="image_id" :value="product.id">
                    <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
                    </div>
                    <div class="product-info">
                        <h3 class="product-title">{{ product.product.product_name }}</h3>
                        <p class="product-brand">By {{ product.product.brand__brand_name }}</p>
                        <p class="product-price">
                        R{{ product.price }}
                        </p>
                        <div  class=" d-flex justify-content-center align-items-center">
                            <button class="action-btn" @click.stop="addToCart(product)">ADD TO CART</button>
                            <i class="fa-sharp fa-regular fa-heart"></i>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<footer class="footer">
  <div class="footer-content">
      <div class="footer-links">
          <div class="footer-column">
              <h3>Shop Now</h3>
              <ul>
                  <li><a href="#">Rich Mnisi</a></li> 
              </ul>
          </div>
          <div class="footer-column">
              <h3>Trends</h3>
              <ul>
                  <li><a href="#">South Africa</a></li> 
              </ul>
          </div>
          <div class="footer-column">
              <h3>Infuencer</h3>
              <ul>
                  <li><a href="#">X</a></li>
                  <li><a href="#">Instagram</a></li> 
              </ul>
          </div>
          <div class="footer-column">
              <h3>Custom Care</h3>
              <ul>
                  <li><a href="#">Contact Us</a></li>
                  <li><a href="#">Payment Method</a></li> 
              </ul>
          </div>
      </div>
      <div class="footer-bottom">
          <div class="footer-legal">
              <p>&copy; 2024 Khiosk. All rights reserved.</p>
              <div class="footer-legal-links">
                  <a href="#">Privacy Policy</a>
                  <a href="#">Terms of Use</a> 
              </div>
          </div>
      </div>
  </div>
</footer>
