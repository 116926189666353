import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';

export default {
    components: {
        Influencer_sidebar,
        Influencer_navbar,
    },
    data: () => ({
      addresses: [],
      addressTypes: []
     }),

      mounted() {
        this.fetchAddressTypes();
        this.fetchAddresses();
      },

      methods: {
        async fetchAddressTypes() {
            const GET_ADDRESS_TYPES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/address_types/`;
            try {
              const response = await fetch(GET_ADDRESS_TYPES_API);

              const data = await response.json();
              this.addressTypes = data || [];
            } catch (error) {
              console.error('Error fetching address types:', error);
            }
        },


        async deleteAddress(address_id) {
          const token = localStorage.getItem('token');
          const DELETE_ADDRESS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/delete_address/${address_id}/`;
        
          try {
            const response = await fetch(DELETE_ADDRESS_API, {
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${token}`
              }
            });
        
            if (response.ok) {
              Swal.fire(
                'Deleted!',
                'The address has been deleted.',
                'success'
              ).then(() => {
                window.location.reload();  // Reload the page after deletion
              });
            }
          } catch (error) {
            console.error(error);
          }
        },


        async fetchAddresses() {
            const token = localStorage.getItem('token');
            const GET_ADDRESSES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_addresses/`;

            try {
              const response = await fetch(GET_ADDRESSES_API, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
                }
              });
      
              if (!response.ok) {
                throw new Error('Failed to fetch addresses.');
              }
      
              const data = await response.json();
              if (data.status === 'success') {
                this.addresses = data.addresses;
              } else {
                this.error = 'No addresses found for this user.';
              }
            } catch (error) {
              this.error = 'An error occurred while fetching addresses.';
            }
        },

      
    }
}