<div class="home-navbar ">
    <Influencer_sidebar />
  </div>
  
  <div class=" app-content wishlist-category">
    <Influencer_navbar />
  
        <div class="card border-0 bg-transparent">
  
          <!-- Tab Content -->
          <div class="tab-content" id="pills-tabContent">
            <!-- Wishlist Tab -->
            <div class="tab-pane fade show active" id="pills-wishlist" role="tabpanel" aria-labelledby="pills-wishlist-tab">
                <div class="mt-5 d-flex align-items-start">
                    <h6>Wishlist</h6>
                </div>
              <div v-if="wishlistProducts.length === 0" class="text-center my-4">
                <i class="fas fa-trash-alt empty-bin-icon"></i>
                <p>Your wishlist is empty</p>
                <p>Add products to create your own wishlist by tapping the heart icon.</p>
              </div>
              <div v-else class="row justify-content-start mt-3">
                <div v-for="product_wish in wishlistProducts" :key="product_wish.id" class="cart-cont d-flex align-items-center mb-3 border p-2 rounded col-9">
                  <img :src="product_wish.productdetail.product.image_url" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" class="cart-item-image" />
                  <div class="cart-item-details flex-grow-1 mx-3">
                    <!-- Product Name -->
                    <h5>{{ product_wish.productdetail.product.product_name }}</h5>
                    <h6 class="text-muted">R{{ product_wish.productdetail.price }}</h6>
                    <h6 class="text-muted">{{ product_wish.productdetail.color }}</h6>
                    <h6 class="text-muted">{{ product_wish.productdetail.stock_status__status_name }}</h6>
                   
                  </div>
                  <!-- Actions Section: Add to Cart & Delete -->
                  <div class="cart-item-actions d-flex align-items-center">
                    <!-- Add to Cart Button -->
                    <button class="add-to-cart bg-warning rounded-12 mr-2" @click="addToCart(product_wish.productdetail.id)">
                      <i class="fa-solid fa-cart-shopping wishlist-icon"></i> Add to Cart
                    </button>
                  
                    <!-- Delete Button -->
                    <button class="btn btn-warning" @click="removeFromWishlist(product_wish.productdetail.id)">
                      <i class="fa-solid fa-trash text-white"></i>
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
  