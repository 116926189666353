<Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />

<div class="container">
  <p class="text-start">
    <router-link to="/" class="text-muted">Home</router-link> / Brand / 
    <span class="fw-bold">{{ brandProducts[0]?.product?.brand__brand_name }}</span>
  </p>

  <div class="row">
    <div class="col-md-3">
      <Filter_product @filter-products="filter_product_list" />
    </div>

    <div class="col-md-9">
      <div v-if="brandProducts.length">
        <div class="items-found">{{ brandProducts.length }} items found</div>  
        <div class="product-grid">
          <div 
            v-for="product in brandProducts" 
            :key="product?.product?.id" 
            class="product-card"
          >
            <router-link 
              :to="{ name: 'product_detail', params: {id: product.id, video_id: product.product.brand_video_id}}" 
              class="product-link"
            >
              <div 
                class="product-image" 
                :style="{ backgroundImage: `url(${product.product.image_url})` }"
              ></div>
              <div class="product-info">
                <h3 class="product-title">{{ product?.product?.product_name }}</h3>
                <p class="product-brand">By {{ product?.product?.brand__brand_name }}</p>
                <p class="product-price">R{{ product?.price }}</p>
              </div>
            </router-link>

            <div class="d-flex justify-content-center align-items-center">
              <button class="action-btn" @click.stop="addToCart(product)">ADD TO CART</button>
              <i class="fa-sharp fa-regular fa-heart"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>