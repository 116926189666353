
<div class="side-panel halfleft"></div>
<div class="side-panel right"></div>

<img :src="require('@/assets/images/Kyosk CI-11.png')" alt="Kyosk Logo" class="top-logo">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">


<div id="app" class="form-container bg-light justify-content-center align-items-center">
    <div class="container">
      <h5>Brand Profile</h5>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <form class="brand-form" @submit.prevent="onSubmit">
            
            <!-- Brand Name Input -->
            <div class="form-group">
              <label for="brand_name" class="col-sm-4 col-form-label">Brand Name:</label>
              <div >
                <input type="text" class="form-control form-control-sm" id="brand_name" v-model="form.brand_name" required>
              </div>
            </div>
            
            <!-- Registration Number and VAT Number -->
            <div class="form-group">
                <div class="row">
              <div class="col-md-6">
                <label for="registration_number" class="col-form-label">Registration Number:</label>
                <input type="text" class="form-control form-control-sm" id="registration_number" v-model="form.registration_number" required>
              </div>
              <div class="col-md-6">
                <label for="vat_number" class="col-form-label">VAT Number:</label>
                <input type="text" class="form-control form-control-sm" id="vat_number" v-model="form.vat_number" required>
              </div>
            </div>
            </div>

            <!-- Upload Logo -->
            <div class="form-group">
                <div class="row align-items-center">
                    <div class="col-md-4 d-flex align-items-center">
                        <label for="logoInput" class="form-label mb-0">Upload Logo:</label>
                    </div>
                    <div class="col-md-8">
                        <input type="file" id="logoInput" accept="image/*" class="form-control-file" @change="onFileChange" />
                        <p v-if="form.fileName">{{ form.fileName }}</p>
                    </div>
                </div>
            </div>

            <div class="custom-spacing"></div>


            <h5>Brand Owner Information</h5>

            <!-- Email and Phone Number -->
            <div class="form-group">
                <div class="row">
              <div class="col-md-6">
                <label for="email" class="col-form-label">Email Address:</label>
                <input type="email" class="form-control form-control-sm" id="email" v-model="form.email" required>
              </div>
              <div class="col-md-6">
                <label for="phone_number" class="col-form-label">Phone Number:</label>
                <input type="tel" class="form-control form-control-sm" id="phone_number" v-model="form.phone_number" required>
              </div>
            </div>
            </div>

            <!-- First Name and Last Name -->
            <div class="form-group">
                <div class="row">
              <div class="col-md-6">
                <label for="owner_first_name" class="col-form-label">Brand Owner's First Name:</label>
                <input type="text" class="form-control form-control-sm" id="owner_first_name" v-model="form.owner_first_name" required>
              </div>
              <div class="col-md-6">
                <label for="owner_last_name" class="col-form-label">Brand Owner's Last Name:</label>
                <input type="text" class="form-control form-control-sm" id="owner_last_name" v-model="form.owner_last_name" required>
              </div>
            </div>
            </div>

            <!-- Submit Button -->
            <button type="submit" variant="primary" class="sub-button">Add</button>
          </form>
        </div>
      </div>
    </div>
  </div>

