<Home_navbar v-if="!LoggedIn" />
        <Influencer_navbar v-else />

<div class="container mt-4">
    <div class="row">
      <div class="col-md-6">
        <div v-if="cartItems.length === 0">
          <p>Your cart is empty.</p>
          <router-link to="/" class="btn btn-primary">Continue Shopping</router-link>
        </div>
        <div v-else>
          
          <div v-for="item in cartItems" :key="item.id" class="cart-item d-flex align-items-center mb-3 border p-2 rounded">
            <img :src="item.image" alt="Product Image" class="cart-item-image" />
            <div class="cart-item-details d-flex mx-3">
              <h5 class="fw-bold productname">{{ item.product_name }}</h5>

              <p>Brand: {{item.brand}}</p>
              <p>size: {{ item.size }}</p>
              <p>color: {{ item.color }}</p>
              <p>Price: R {{ (item.price * item.quantity) }}</p> <!-- Display total price based on quantity -->
              <div class="quantity-selector d-flex align-items-center">
                <button class="btn btn-outline-secondary btn-sm" @click="decreaseQuantity(item.id,item.product_id)">-</button>
                <span class="mx-2">{{ item.quantity }}</span>
                <button class="btn btn-outline-secondary btn-sm" @click="increaseQuantity(item.id,item.product_id)">+</button>
              </div>
            </div>
            <button class="btn btn-warning" @click="removeFromCart(item.id, item.product_id)">
              <i class="fa-solid fa-trash text-white"></i>
            </button>
            
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="cart-cont">
          <h3 class="fw-bolder">Cart Summary</h3>
          <div class="d-flex justify-content-between fw-bolder mt-3">
              <span>Sub Total</span>
              <span>R {{cartTotal}}</span>
          </div>
          <div class="d-flex justify-content-between fw-bolder mt-3">
              <span>Delivery Fee</span>
              <span>R 0</span>
          </div>
          <div class="d-flex justify-content-between fw-bolder mt-3">
              <span>Total</span>
              <span>R {{cartTotal}}</span>
          </div>
          <div class="d-flex justify-content-center">
              <button class="create-btn fw-bolder mb-4" @click="proceedToCheckout">Proceed to Checkout</button>
          </div>
      </div>
      </div>
    </div>
  </div>


  <footer class="footer">
    <div class="footer-content">
        <div class="footer-links">
            <div class="footer-column">
                <h3>Shop Now</h3>
                <ul>
                    <li><a href="#">Rich Mnisi</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Trends</h3>
                <ul>
                    <li><a href="#">South Africa</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Influencer</h3>
                <ul>
                    <li><a href="#">X</a></li>
                    <li><a href="#">Instagram</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Custom Care</h3>
                <ul>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Payment Method</a></li> 
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-legal">
                <p>&copy; 2024 Khiosk. All rights reserved.</p>
                <div class="footer-legal-links">
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of Use</a> 
                </div>
            </div>
        </div>
    </div>
</footer>