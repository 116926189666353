
<nav class="influencer-top-nav navbar navbar-expand-lg navbar-dark bg-dark z-3 rounded-12 mt-2" >
    <div class="container-fluid">
        <i class='bx bx-menu fs-4 text-white ms-3 close-sidebar' @click="toggleSidebar"></i>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item text-white">
                    <router-link to="/Influencer" class="text-white">
                        Home
                    </router-link> 
                </li>
                <li class="nav-item text-white">
                    <a href="#" data-toggle="modal" data-target="#uploadModal" class="text-white">
                        Create 
                    </a>
                </li>
                <li class="nav-item text-white">
                    <router-link to="/Influencer_profile" class="text-white">
                        Profile 
                    </router-link>                  
                </li> 
            </ul>

            
        </div>
        <div class="navbar-nav-mobile">
            <p class=" text-light text-white">
                <router-link to="/Influencer">
                    Home
                </router-link> 
            </p>
            <p class="nav-item ms-4 text-white">
                <a href="#" data-toggle="modal" data-target="#uploadModal">
                    Create 
                </a>
            </p>
            <p class="nav-item ms-4 text-white">
                <router-link to="/Influencer_profile">
                    Profile 
                </router-link>                  
            </p> 
        </div>
       
        <div class="d-flex align-items-center">
            <i class="bx bx-search nav-icon text-white me-3" id="searchIcon"></i>  
            <a href="#" @click="toggleCart">
                <i class='bx bx-shopping-bag nav-icon text-white me-2' id="cart-icon"></i>
                <span class="cart-quantityc" id="cart-quantity">{{ cartQuantity }}</span>
              </a>           
              <a href="#" @click="logout"> 

                <i class="bx bx-log-in nav-icon text-white me-3"></i>  
              </a>          
        </div>
    </div>
</nav>
<div class="search-input bg-dark p-2 d-none" id="search_input" :value="search_input" @input="update_search">
    <div class="container-fluid">
        <input type="text" class="form-control" placeholder="Search...">
    </div>
</div>
 
 <!-- Main Sidebar -->
 <div class="influencer-category-navbar-sm">
  <div :class="{'open': sidebarOpen}" id="sidebar">
    
    <!-- Departments Loop -->
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
      <a href="#" class="back-btn" @click="toggleSidebar"><i class='bx bx-arrow-back'></i></a>

      <a href="#" class="sidebar-item department-name" @click.prevent="toggleSubSidebar(departmentIndex)">
        {{ department.department_name }}
      </a>
        
      <!-- Sub-Sidebar for Categories -->
      <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar" v-if="department.categories.length">
        <a href="#" class="back-btn" @click="toggleSubSidebar(departmentIndex)"><i class='bx bx-arrow-back'></i> Back to Main</a>
        
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
          <a href="#" 
            class="sidebar-item dropdown-toggle category-name" 
            data-bs-toggle="collapse" 
            :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex" 
            aria-expanded="false" 
            :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
            {{ category.category_name }}
          </a>

          <div class="collapse" :id="'categoryDropdown' + departmentIndex + categoryIndex" v-if="category.subcategories.length">
            <a v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
              :key="subcategoryIndex" 
              href="#" 
              class="dropdown-item">
              {{ subcategoryName.subcategory_name }}        
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div v-if="isCartOpen && cartQuantity > 0" class="cart-dropdown" ref="cartDropdown" @mouseleave="closeCart">

    <div class="cart-items">
      <div v-for="item in cartItems" :key="item.id" class="cart-item">
        <img :src="item.image" alt="Product image" class="cart-item-image" />
        <div class="cart-item-details d-flex">
            <h4 class="fw-bold productname">{{ item.product_name }}</h4>          
  
          <p>Brand: {{ item.brand }}</p>
          <p>size: {{ item.size }}</p>
          <p>color: {{ item.color }}</p>
          <p>Price: R {{ (item.price * item.quantity) }}</p>
          <div class="d-flex align-items-center">
            <span class="me-2">Qty:</span>
            <button v-if="item.quantity === 1" class="quantity-btn btn btn-outline-secondary btn-sm" @click="deleteCartItem(item.product_id)">
              <i class="fa-solid fa-trash"></i>
            </button>
            <button v-else class="quantity-btn btn btn-outline-secondary btn-sm" @click="decreaseQuantity(item)">-</button>
            <span class="quantity mx-2">{{ item.quantity }}</span>
            <button class="quantity-btn btn btn-outline-secondary btn-sm" @click="increaseQuantity(item)">+</button>
        </div>
        
        </div>
      </div>
    </div>
    <div class="cart-total d-flex justify-content-between">
      <p>Total:</p>
      <p>{{ cartTotal }}</p>
    </div>
    <router-link to="/cart" class="view-cart-btn fw-bold mt-2">
        View Cart ({{ cartQuantity }})
      </router-link>
    </div>


    
<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                <h5 class="modal-title" id="uploadModalLabel">Video Upload</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <!-- Video Section -->
                    <div class="col-md-6">
                      <div class=" ">
                        <label for="videoUpload" class="upload-label" id="uploadLabel">
                          <div class="upload-image-icon" id="uploadIcon">📤</div>
                          <p id="uploadText">Click to upload file</p>
                          <video id="videoPreview" controls class="video-preview"></video>
                        </label>
                        <input type="file" id="video_url" accept="video/*" class="file-inputs" @change="handleFileChange">
                      </div>
                    </div>
                  
                    <!-- Form Section -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="caption">Caption:</label>
                        <input type="text" v-model="caption" id="caption" class="form-control form-control-sm">
                        <small v-if="showCaptionError" class="text-danger">Please provide a caption.</small>
                      </div>
                    <div class="form-group position-relative">
                        <label for="brand">Tag Brand:</label>
                        <input type="text" v-model="tagInput" id="tagInput" class="form-control" placeholder="Type @ to tag brands" @keyup="filterBrands" @blur="validateBrand">
                        <!-- Dropdown for brands -->
                        <ul v-if="showBrandDropdown" class="dropdown-menu show">
                          <li v-for="brand in filteredBrands" :key="brand.id" @click="selectBrand(brand)" class="dropdown-item">
                            {{brand.brand_name}}
                          </li>
                        </ul>
                        <small v-if="brandError" class="text-danger">Please select a valid brand from the list.</small>
                      </div>
  
                      <div class="form-group position-relative">
                        <label for="productSearch">Search Products:</label>
                        <input type="text" v-model="searchQuery" id="products" class="form-control" placeholder="Type product name" @keyup="filterTaggedProducts">
                        <!-- Dropdown for products -->
                        <ul v-if="showProductDropdown" class="dropdown-menu show">
                          <li v-for="product in filtered_tagged_products" :key="product.product_id" @click="selectProduct(product)" class="dropdown-item d-flex align-items-center">
                            <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                            <span>{{ product.product.product_name }}</span>
                          </li>
                        </ul>
                      </div>
                      
                      <!-- Display selected products -->
                      <div v-if="selectedProducts.length > 0">
                        <h5>Selected Products:</h5>
                        <ul>
                          <li v-for="product in selectedProducts" :key="product.id" class="d-flex align-items-center">
                            <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                            <span>{{ product.product.product_name }}</span>
                            <span @click="removeProduct(product)" class="ml-2 text-danger" style="cursor: pointer;">&times;</span>
                        </li>
                        </ul>
                      </div>
                      
                      </div>
                      <br>
            
                    <div class="modal-footer">
                        <button type="submit" class="action-btn" >Upload</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

 



