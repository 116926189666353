<div class="home-navbar ">
    
  <div class="top-banner">
    <div class="row"> 
      <div class="col-md-3 col-12 d-flex justify-content-start align-items-center"> 
        <div class="col top-banner-sm">
          <a href="#" 
                     class="btn btn-link" 
                     :class="{ active: currentRole === 'customer' }"
                     @click.prevent="selectRole('customer')">Customer</a>
                  <a href="#" 
                     class="btn btn-link" 
                     :class="{ active: currentRole === 'brand' }"
                     @click.prevent="selectRole('brand')">Brand</a>
                  <a href="#" 
                     class="btn btn-link" 
                     :class="{ active: currentRole === 'influencer' }"
                     @click.prevent="selectRole('influencer')">Influencer</a>
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-center align-items-center">
          Free delivery on orders over R1,000.00
      </div>

      
    </div>
  </div>
 
  <nav class="first-navbar">
    <i class='bx bx-menu fs-4 text-dark ms-3 close-sidebar' @click="toggleSidebar"></i> 

    <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class=" logo" @click="goToLandingPage" >
    <div class="search-bar search-bar-lg">
        <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
        <div class="search-dropdown" id="search-dropdown">
            <a href="#">Popular search 1</a>
            <a href="#">Popular search 2</a>
            <a href="#">Popular search 3</a>
            <a href="#">Popular search 4</a>
            <a href="#">Popular search 5</a>
        </div>
    </div>
    <div class="nav-icons">
        <div class="icon-container" @click="toggleCart" ref="iconContainer">
          <!-- <div class="icon-container" data-dropdown> -->
          <a href="#">
            <i class='bx bx-shopping-bag' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        <div class="icon-container" data-dropdown>
            <a href="#"><i class='bx bx-user'></i></a>
            <div class="dropdown-content"> 
                <router-link to="/register" class="">
                  <span class="text-dark" >Sign Up</span>
                </router-link>
       
                <router-link to="/login" class=" ">
                  <span class="text-dark">Login</span>
                </router-link>
            </div>
        </div>       
    </div>
  </nav>
  <div class="search-bar mt-3 search-bar-sm">
    <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
    <div class="search-dropdown" id="search-dropdown"> 
    </div>
</div>
 <br>
  <nav class="second-navbar" :class="{'sidebar-visible': isSidebarVisible}">
    <div class="nav-item" data-dropdown>
        <a href="#">NEW IN</a>
        <div class="dropdown-content">
            <a href="#">Women</a>
            <a href="#">Men</a>
            <a href="#">Kids</a>
        </div>
    </div>
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item" data-dropdown>
      
      <a href="#" class="department-name">{{ department.department_name }}</a>
      <div class="dropdown-content align-items justify-content-center">
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex" class="subcategory-section">
          <a href="#" class="category-name">{{ category.category_name }}</a>
          <div v-if="category.subcategories.length" class="subcategory-container">
            <a v-for="(subcategoryName, subcategoryIndex) in category.subcategories" :key="subcategoryIndex" href="#">{{ subcategoryName.subcategory_name }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="#">BRANDS</a>
        <div class="dropdown-content">
            <a href="#">Top Brands</a>
            <a href="#">Local Brands</a>
            <a href="#">International Brands</a>
        </div>
    </div>
  </nav>
</div>

<!-- <div v-if="isCartOpen" class="cart-dropdown"> -->
<div v-if="isCartOpen && cartQuantity > 0" class="cart-dropdown" ref="cartDropdown" @mouseleave="closeCart">

  
  <div class="cart-items">
    <div v-for="item in cartItems" :key="item.id" class="cart-item">
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex">
        <h4 class="fw-bold productname">{{ item.product_name }}</h4>
        
        <p>Brand: {{ item.brand }}</p>
        <p>size: {{ item.size }}</p>
        <p>color: {{ item.color }}</p>
        <p>Price: R {{ (item.price * item.quantity) }}</p>
     
        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
          <button v-if="item.quantity === 1" class="quantity-btn btn btn-outline-secondary btn-sm" @click="deleteFromCart(item.id, item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
          <button v-else class="quantity-btn btn btn-outline-secondary btn-sm" @click="decreaseQuantity(item.id,item.product_id)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
          <button class="quantity-btn btn btn-outline-secondary btn-sm" @click="increaseQuantity(item.id,item.product_id)">+</button>
      </div>

        
      </div>
    </div>
  </div>
  <div class="cart-total d-flex justify-content-between">
    <p>Total:</p>
    <p>{{ cartTotal }}</p>
  </div>
  <router-link to="/cart" class="view-cart-btn fw-bold mt-2">
    View Cart ({{ cartQuantity }})
  </router-link>
  
  <!-- <button @click="goToCartPage"  class="view-cart-btn fw-bold mt-2">View Cart ({{ cartQuantity }})</button> -->
</div>

<!-- Main Sidebar -->
<div :class="{'open': sidebarOpen}" id="sidebar">
  <a href="#" class="btn btn-link" 
     :class="{ active: selectedRole === 'customer' }" 
     @click.prevent="selectRole('customer')">Customer</a>
  <a href="#" class="btn btn-link" 
     :class="{ active: selectedRole === 'brand' }" 
     @click.prevent="selectRole('brand')">Brand</a>
  <a href="#" class="btn btn-link" 
     :class="{ active: selectedRole === 'influencer' }" 
     @click.prevent="selectRole('influencer')">Influencer</a>
  <a href="#" class="back-btn" @click="toggleSidebar"><i class='bx bx-arrow-back'></i></a>

  <!-- Departments Loop -->
  <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
    <a href="#" class="sidebar-item department-name" @click.prevent="toggleSubSidebar(departmentIndex)">
      {{ department.department_name }}
    </a>
      
    <!-- Sub-Sidebar for Categories -->
    <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar" v-if="department.categories.length">
      <a href="#" class="back-btn" @click="toggleSubSidebar(departmentIndex)"><i class='bx bx-arrow-back'></i> Back to Main</a>
      
      <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
        <a href="#" 
           class="sidebar-item dropdown-toggle category-name" 
           data-bs-toggle="collapse" 
           :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex" 
           aria-expanded="false" 
           :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
          {{ category.category_name }}
        </a>

        <div class="collapse" :id="'categoryDropdown' + departmentIndex + categoryIndex" v-if="category.subcategories.length">
          <a v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
             :key="subcategoryIndex" 
             href="#" 
             class="dropdown-item">
            {{ subcategoryName.subcategory_name }}        
          </a>
        </div>
      </div>
    </div>
  </div>
</div>






 