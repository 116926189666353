<div class="container-fluid  mt-2">
    <Customer_navbar />
    <Products_navbar />
    <br>
    <br>
    <div v-if="brand_videos.length > 0">
      <div class="hero">
        <div class="video-container  border-0">
          <div class="fade-overlay"></div>        
              <video :src="brand_videos[0].video_url" autoplay >
                  Your browser does not support the video tag.
              </video>
          </div>
          <div class="row">
            <div class="col-md-5">
                <div class="hero-content text-start text-light">
                  <div class="d-flex align-content-center d-none">
                      <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
                      <h4 class="ms-2 mt-n1">Khiosk</h4>
                    </div>  
                  <p></p>
                  <button class="btn btn-light text-dark" data-toggle="modal" data-target="#exampleModal"> <i class='bx bx-play-circle me-1'></i>Play</button>
                </div>
            </div>
            <div class="col-md-6">
              <div class="hero-cards hero-product">
                <div class="bg-transparent">
                  <div class="scroller-container"> 
                    <div class="sub-scroller" ref="scroller">
                      <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                      <div class="logo-description-container  "> 
                        <div class="justify-content-end">
                          <router-link :to="{name: 'product', params: {id: image.id}}" v-for="(image, index) in image_files" :key="index" class="card h-100">
                            <input type="hidden" ref="image_id" :value="image.id">
                            <img :src="image.product.image_url" 
                              class="rounded-12 p-1 bg-white h-150 img-fluid card-img-top" 
                              @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  
                            >
                            <div class="card-body text-start"> 
                              <p class="card-text description text-white"> {{ image.product.product_name }}</p>
                              <p class="card-text description text-white"> {{ image.product.brand__brand_name  }}</p>
                              <h5 class="card-title text-warning fw-bold">R{{ image.price }}</h5>
                            </div>
                          </router-link> 
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <br>
        
        <!-- New Collection SECTION -->
        <h3 class="mt-4">New Collection</h3>
        <div class="px-5 d-flow-root subvideo-content mb-5">
          <div class="scroller-container">
            <div class="scroller">
              <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
                <div v-for="(video, index) in brand_videos" :key="index" class="card">                    
                  <div @click="setCurrentVideo(video.id)" data-toggle="modal" :data-target="'#videoModal' + index" style="cursor: pointer;">
                  <!-- Hover Preview Video -->
                      <img v-if="hoveredVideo !== index" 
                      :src="video.cover_image" 
                      @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  
                      class="video-cover rounded-12 img-fluid h-250 object-fit-cover"/>
                      <video v-if="hoveredVideo === index" :src="video.video_url" autoplay class="rounded-12 video-preview" @mouseenter="showPopUp(index)" @mouseleave="hidePopUp(index)"></video>
                  </div>
                  <div class="  text-start mt-2 ms-1">
                      <h6 class="fw-bold">{{ video.description }}</h6>
                      <p class="text-muted">{{ video.sub_description }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Scroll Buttons -->
            <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
            <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
          </div>
        </div>
  
        <!-- Modal Structure (Repeat for each video dynamically) -->
        <div v-for="(video, index) in brand_videos" :key="index" class="modal fade" :id="'videoModal' + index" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" @hidden.bs.modal="pauseVideo(index)">
          <div class="modal-dialog modal-dialog-centered modal-fullscreen new-collection-modal" role="document">
            <div class="modal-content"> 
              <div class="modal-header">
                  <h5 class="modal-title"></h5>
                  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body card border-0"> 
                <div class="video-container border-0">
                  <!-- Video Player -->
                  <video :ref="'video_' + index" :id="'video_' + index" :src="video.video_url" autoplay muted loop controls>
                      Your browser does not support the video tag.
                  </video>
                  <!-- Images for the selected video -->
                  <div class="col-md-12">
                    <div class="hero-cards hero-product">
                      <div class="bg-transparent">
                        <div class="scroller-container"> 
                          <div class="sub-scroller" ref="scroller">
                            <div class="logo-description-container"> 
                              <div class=" d-flex justify-content-center gap-12 "> 
                                <div v-for="(image, index) in images" :key="index">                                              
                                  <img :src="image.product.image_url" 
                                    class="rounded-12 p-1 bg-white h-250" 
                                    @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  
                                  >                                
                                  <div class="card-body text-start"> 
                                      <p class="card-text description text-white"> {{ image.product.product_name }}</p>
                                      <p class="card-text description text-white"> {{ image.product.brand__brand_name  }}</p>
                                      <h5 class="card-title text-warning fw-bold">R{{ image.price }}</h5>
                                  </div>
                                </div> 
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- PLAY Modal -->
        <div class="modal fade hero-play-modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
            <div class="modal-content"> 
              <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body card border-0"> 
                <div class="video-container  border-0">  
                  <video :src="brand_videos[0].video_url" autoplay muted loop controls>
                      Your browser does not support the video tag.
                  </video>
                  <div class="col-md-6">
                    <div class="hero-cards hero-product">
                      <div class="bg-transparent">
                        <div class="scroller-container"> 
                          <div class="sub-scroller" ref="scroller">
                            <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                            <div class="logo-description-container  "> 
                              <div class="justify-content-end">
                                <div :to="{name: 'product', params: {id: image.id}}" v-for="(image, index) in image_files" :key="index" class="card h-100">
                                  <input type="hidden" ref="image_id" :value="image.id">
                                  <img 
                                    :src="image.product.image_url" 
                                    class="rounded-12 p-1 bg-white h-150 img-fluid card-img-top" 
                                    @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  
                                  >
                                  <div class="card-body text-start"> 
                                    <p class="card-text description text-white"> {{ image.product.product_name }}</p>
                                    <p class="card-text description text-white"> {{ image.product.brand__brand_name  }}</p>
                                    <h5 class="card-title text-warning fw-bold">R{{ image.price }}</h5>
                                  </div>
                                </div> 
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  
  <div class="container home-product">
    <h1 class="text-center my-4 ms-4 text-dark fw-bold">Latest Offers This Month</h1>
     
    <div class="row">
      <div class="card card-large">
          <span class="card-text text-dark">New Collection</span>
      </div>
      <div class="card card-small">
          <span class="card-text">Trends</span>
      </div>
    </div>
  
    <div class="row">
      <div class="card card-image home-band">
            <span class="card-text text-white">Brands  </span>
      </div>
      <div class="card card-image home-influencer">
          
          <span class="card-text text-white">Influencer  </span>
      </div>
      <div class="card card-image home-shop"> 
          <img :src="require('@/assets/images/logo-icon-black.png')" alt="Kyosk Logo" class="w-25 h-25 rounded-12 d-none">
  
          <span class="card-text text-white">Shop Now </span>
      </div>
    </div>
  
  </div>
  
  <footer class="footer">
    <div class="footer-content">
        <div class="footer-links">
            <div class="footer-column">
                <h3>Shop Now</h3>
                <ul>
                    <li><a href="#">Rich Mnisi</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Trends</h3>
                <ul>
                    <li><a href="#">South Africa</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Infuencer</h3>
                <ul>
                    <li><a href="#">X</a></li>
                    <li><a href="#">Instagram</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Custom Care</h3>
                <ul>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Payment Method</a></li> 
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-legal">
                <p>&copy; 2024 Khiosk. All rights reserved.</p>
                <div class="footer-legal-links">
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of Use</a> 
                </div>
            </div>
        </div>
    </div>
  </footer>
  

