import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';

export default {
    components: {
        Home_navbar,
      },
      data: () => ({ items: []}),
      async mounted() {
        await this.fetchInfluencers();
      },
      methods: {
        async fetchInfluencers() {
          const FETCH_INFLUENCER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_api/`;
          try {
            const response = await fetch(FETCH_INFLUENCER_URL, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              },
            });
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            this.items = data;  // Update component data
          } catch (error) {
            console.error('Error fetching influencers:', error);
          }
        }
      }
    }
  