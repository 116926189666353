
export default{
    name: 'CheckoutNavbarView',

    methods: {
        goToLandingPage() {
            this.$router.push('/influencer'); 
        },

        goBack() {
            this.$router.go(-1)
        },
    }
}