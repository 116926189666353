import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Products_navbar from '@/components/Products/ProductNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue';
import axios from 'axios';
import jQuery from 'jquery';
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router'; 

export default {
  name: 'ContentUploadView',
  components: {
    Influencer_sidebar,
    Influencer_navbar,
    Products_navbar,
    Filter_product,
  },
  data() {
    return {
      brandError: false,
      showCaptionError: false,
      search_query: '',
      search_input: '',
      filteredProducts: [],

    };
  },

  setup() {
    const router = useRouter();
    const videos = ref([]); // Replace with your actual videos data source
    const showDetails = ref([]); // Array to keep track of which video's details are shown
    const visibleCards = ref(6);
    const currentPosition = ref(0);
    const hoveredVideo = ref(null);
    const brand_videos = ref([]);
    const image_files = ref([]);
    const currentVideoUrl =ref('');
    const currentVideoTitle = ref('');
    const currentVideoId = ref(null);
    const images = ref([]);
    const isFullScreen = ref(false); // Fullscreen toggle
    const selectedVideo = ref(null);

    // Dropdown related variables
    const filteredProducts = ref([]);
    const brands = ref([]);   
    const products = ref([]);
    const video_products = ref([]);

    const search_query = ref('');
    const allProducts = ref([]);
    const showDropdown = ref(false);

    const filter_product_list = (data) =>{
      allProducts.value = data;
      filteredProducts.value = data;
    };

    // Initialize showDetails array with false values
    const initializeShowDetails = () => {
      showDetails.value = new Array(videos.value.length).fill(false);
    };

    // Toggle the visibility of details for the clicked video
    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!showDetails.value[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    };
    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };
    const scrollCards = (direction) => {
      const maxScroll = brand_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    const onImageClick = (image_id) => {
      // Fetch products before navigating to the new route
      fetchProducts().then(() => {
        // Construct the URL for the product route
        const product_url = router.resolve({ name: 'product', params: { id: image_id } }).href;

        // Change the window location to the new URL and refresh the page
        window.location.href = product_url;
        window.location.reload(); // This will refresh the page
      });
    };
    const fetchProducts = async () => {
      try {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;

        const response = await fetch(FETCH_PRODUCT_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        products.value = data;
        allProducts .value = data;
        filteredProducts.value = data;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const filterProducts = () => {
      const query = search_query.value.toLowerCase();
      filteredProducts.value = [...allProducts.value];
      if (query) {
          const search = new RegExp(query, 'i');
          filteredProducts.value = filteredProducts.value.filter((product) => {
              const match = (
                  search.test(product.product.product_name) ||
                  search.test(product.product.brand__brand_name) ||
                  search.test(product.product.description) ||
                  search.test(product.product.category__category_name)
              );
              return match;
          });
      }
      
    };
    const loadVideoImages = async (videoId) => {
      const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${videoId}`;
      
      try {
        const response = await fetch(VIEW_IMAGE_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
    
        const data = await response.json();
        images.value = data;
    
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };
    function getProductImages(videoId) {
      return images.value[videoId] || [];
    }
    const displayedVideos = computed(() => {
      const totalVideos = brand_videos.value.length;
    
      if (totalVideos === 0) return [];
    
      const previousIndex = currentPosition.value > 0 ? currentPosition.value - 1 : null;
      const nextIndex = currentPosition.value < totalVideos - 1 ? currentPosition.value + 1 : null;
      
      const videosWithImages = [
        previousIndex !== null ? {
          ...brand_videos.value[previousIndex],
          product_images: getProductImages(brand_videos.value[previousIndex].id),
        } : null,
        {
          ...brand_videos.value[currentPosition.value],
          product_images: getProductImages(brand_videos.value[currentPosition.value].id),
        },
        nextIndex !== null ? {
          ...brand_videos.value[nextIndex],
          product_images: getProductImages(brand_videos.value[nextIndex].id),
        } : null,
      ].filter(Boolean); // Remove null entries
    
    
      return videosWithImages;
    });
  
    const openFullScreen = async (videoId, index) => {
      selectedVideo.value = brand_videos.value[index];
      currentPosition.value = index; 
     
      isFullScreen.value = true;
      await loadVideoImages(videoId);
    };


    const closeFullScreen = () => {
      isFullScreen.value = false; 
      selectedVideo.value = null; 
      images.value = []; // Reset images if you have them define
    };

    const nextVideo = async () => {
      currentPosition.value = (currentPosition.value + 1) % brand_videos.value.length; // Move to the next index
      selectedVideo.value = brand_videos.value[currentPosition.value]; // Update the selected video
      await loadVideoImages(selectedVideo.value.id); // Load the images for the new video
    };

    const previousVideo = async () => {
      currentPosition.value = (currentPosition.value - 1 + brand_videos.value.length) % brand_videos.value.length; // Move to the previous index
      selectedVideo.value = brand_videos.value[currentPosition.value]; // Update the selected video
      await loadVideoImages(selectedVideo.value.id); // Load the images for the new video
    };
    const handlePause = (event) =>{
      if (selectedVideo.value && event.target.src !== selectedVideo.value.video_url) {
        event.target.pause();
      }
    };
    onMounted(() => {
      updateVisibleCards();
      
      // jQuery event handlers
      jQuery(window).on('resize', updateVisibleCards);
     
    });

    initializeShowDetails();
    watch(search_query, filterProducts);
    return { 
      videos:[],
      brands,
      products,
      filteredProducts,
      selectedProducts: [],
      brand_videos,
      image_files,
      hoveredVideo,
      currentVideoUrl,
      showDropdown,
      currentVideoTitle, 
      images,
      currentVideoId, 
      video_products,
      visibleCards,
      currentPosition,
      isPlaying: false, // Flag to track video playback state
      debounceTimer: null, // Timer for debounce
      selectedVideo,
      isFullScreen,
      toggleDetails,
      toggleOverlay,
      scrollCards,
      onImageClick,
      filterProducts,
      fetchProducts,
      filter_product_list,
      nextVideo,
      openFullScreen,
      closeFullScreen,
      previousVideo,
      displayedVideos,
      handlePause
      
    };
    
},
  methods: {
    update_search(event) {
      this.$emit('update:searchIinput', event.target.value);
      this.search_query = event.target.value;
      this.filterProducts();
        },
    async fetchBrandVideos() {
      try {
        const GET_ALL__BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
        const response = await fetch(GET_ALL__BRAND_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        this.brand_videos = data;
        this.loadImages();
        if (this.brand_videos.length > 0) {
          this.loadImagesForVideos();
        }
         
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const VIEW_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_content/`;

        const response = await fetch(VIEW_CONTENT_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.videos = data; 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
  
    },
    
    loadImages() {
      if (this.brand_videos && this.brand_videos.length > 0) {  // Check if brand_videos exists and has data
        const video_id = this.brand_videos[0].id;  // Access the video ID
  
        const token = localStorage.getItem('token');
        const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video_id}`;
  
        axios.get(VIEW_IMAGE_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        .then(response => {
          this.image_files = response.data; // Assuming response.data is an array of image data
        })
        .catch(error => {
          console.error('Error loading images:', error);
        });
      }
    },
  
 

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
  toggleDetails(index) {
    // Your method to toggle details view
    this.$set(this.showDetails, index, !this.showDetails[index]);
  },
  showPopUp(index) {
    this.hoveredVideo = index;
    const videoElement = document.querySelectorAll('video')[index];
    if (videoElement) {
      videoElement.play().catch(error => console.error("Error playing video:", error));
    }
  },
  hidePopUp(index) {
    this.hoveredVideo = null;
    const videoElement = document.querySelectorAll('video')[index];
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  },

  setCurrentVideo  (videoId) {
    this.currentVideoId = videoId;
    this.loadVideoImages(videoId);
  },
  loadVideoImages  (videoId)  {
    const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${videoId}`;

    axios.get(VIEW_IMAGE_URL, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        this.images = response.data;
    })
    .catch(error => {
        console.error('Error loading images:', error);
    });
  },
  loadImagesForVideos()  {
    if (!this.brand_videos || this.brand_videos.length === 0) {
      console.error('No videos available to load images for.');
      return;
    }

    // Loop through each video and fetch images for that video
    for (let video of this.brand_videos) {
      try {
        const VIEW_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video.id}`;

        axios.get(VIEW_PRODUCTS_URL, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
          video.video_products = response.data; // Add images to the video object

        })
      } catch (error) {
        console.error(`Error fetching images for video ${video.id}:`, error);
      }
    }
  },

  handleScroll() {
    // Clear the existing timer if it exists
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new timer to delay the play/pause action
    this.debounceTimer = setTimeout(() => {
      const video = this.$refs.heroVideo;
      

      // Check if the video reference exists
      if (video) {
        const rect = video.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the video is in view
        if (rect.top >= 0 && rect.bottom <= windowHeight) {
          // Play the video if in view and not already playing
          if (video.paused && !this.isPlaying) {
            video.play().then(() => {
              this.isPlaying = true; // Update flag when playing
            }).catch((error) => {
              console.error("Error playing video:", error);
            });
          }
        } else {
          // Pause the video if out of view and currently playing
          if (!video.paused && this.isPlaying) {
            video.pause();
            this.isPlaying = false; // Update flag when paused
          }
        }
      } else {
        console.warn("Video reference is null.");
      }
    }, 100); // Adjust the delay (in milliseconds) as needed
  },


},

  mounted() {
    this.fetchBrandVideos();
    this.fetchVideos();
    this.handleClickOutside();
    this.loadImages();
    this.setCurrentVideo();
    this.loadVideoImages();
    this.loadImagesForVideos();
    this.fetchProducts();
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    this.handleClickOutside();
    window.removeEventListener('scroll', this.handleScroll);

  }
  
};



