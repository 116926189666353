/* global google */
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    Influencer_sidebar,
    Influencer_navbar,
  },
  name: 'DeliveryView',
  data: () => ({
    form: {
      address_type: '',
      recipient_name: '',
      recipient_mobile: '',
      business_name: '',
      street_address: '',
      complex_building: '',
      suburb: '',
      city_town: '',
      province: '',
      postal_code: ''
    },
    provinces: [],
    address_types: [],
    suggestions: [],
    errors: {},
    GOOGLE_API: '',
    address_id: '',
  }),
  computed: {
    isBusinessType() {
      const selected_type = this.address_types.find(type => type.id === this.form.address_type);
      return selected_type && selected_type.type_name === 'Business';
    }
  },

  mounted() {
    this.address_id = this.$route.query.address_id || localStorage.getItem('address_id');
    this.fetchAddressData();
    this.fetchProvinces();
    this.fetchAddressTypes();
    this.fetchApiKey()  // Fetch the API key first
      .then(() => {
        this.loadGoogleMapsAPI(this.GOOGLE_API)  // Initialize Google Maps once the API key is ready
          .then(() => {
            this.onAddressInput();
          })
          .catch(error => {
            console.error('Error loading Google Maps API:', error);
        });
      })
      .catch(error => {
        console.error('Error fetching API key:', error);
      });
  },

  methods: {
    async fetchApiKey() {
      const API_KEY_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/google_maps_key/`;
      try {
        const response = await fetch(API_KEY_URL);
        if (!response.ok) throw new Error('Failed to fetch API key');
        const data = await response.json(); 
        this.GOOGLE_API = data.api_key;
        return Promise.resolve();  // Resolve the promise once the API key is fetched     
      } catch (error) {
        console.error('Error fetching API key:', error);
      }
    },

    async fetchAddressData() {
        if (this.address_id) {
          const token = localStorage.getItem('token');
          const ADDRESS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_address/${this.address_id}/`;
      
          try {
            const response = await fetch(ADDRESS_API, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
              }
            });
      
            if (!response.ok) {
              throw new Error('Failed to fetch address');
            }
      
            const addressData = await response.json();
      
            // Update form data with fetched address details
            this.form.address_type = addressData.address_type;
            this.form.recipient_name = addressData.recipient_name;
            this.form.recipient_mobile = addressData.recipient_mobile;
            this.form.business_name = addressData.business_name;
            this.form.street_address = addressData.street_address;
            this.form.complex_building = addressData.complex_building;
            this.form.suburb = addressData.suburb;
            this.form.city_town = addressData.city_town;
            this.form.province = addressData.province;
            this.form.postal_code = addressData.postal_code;
          } catch (error) {
            console.error('Error fetching address:', error);
          }
        }
      },      

    async fetchProvinces() {
      const PROVINCES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/provinces/`;
      try {
        const response = await fetch(PROVINCES_API);
        if (!response.ok) 
          throw new Error('Failed to fetch provinces');
        this.provinces = await response.json();
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    },

    async fetchAddressTypes() {
      const ADDRESS_TYPE_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/address_types/`;
      try {
        const response = await fetch(ADDRESS_TYPE_API);
        if (!response.ok){ 
          throw new Error('Failed to fetch address types');
        }
        this.address_types = await response.json();
        
        const residential_type = this.address_types.find(type => type.type_name === 'Residential');
        if (residential_type) {
          this.form.address_type = residential_type.id;
        }
      } catch (error) {
        console.error('Error fetching address types:', error);
      }
    },

    validateForm() {
        this.errors = {};

        if (!this.form.recipient_name || !/^[a-zA-Z ]+$/.test(this.form.recipient_name)) {
            this.errors.recipient_name = 'name should contain only letters';
        }

        if (!this.form.recipient_mobile || !/^\d{10}$/.test(this.form.recipient_mobile)) {
            this.errors.recipient_mobile = 'mobile number should be 10 digits';
        }

        if (this.isBusinessType && !this.form.business_name) {
            this.errors.business_name = 'Business name is required for business addresses';
        }

        if (!this.form.street_address) {
            this.errors.street_address = 'Street address is required';
        }

        if (!this.form.suburb || !/^[a-zA-Z ]+$/.test(this.form.suburb)) {
            this.errors.suburb = 'Suburb should contain only letters';
        }

        if (!this.form.city_town || !/^[a-zA-Z ]+$/.test(this.form.city_town)) {
            this.errors.city_town = 'City/Town should contain only letters';
        }

        if (!this.form.province) {
            this.errors.province = 'Province is required';
        }

        if (!this.form.postal_code || !/^\d{4}$/.test(this.form.postal_code)) {
            this.errors.postal_code = 'Postal code should be 4 digits';
        }

        return Object.keys(this.errors).length === 0;
    },


    async onSubmit() {
        if (this.validateForm()) {
            const token = localStorage.getItem('token');
            const SAVE_ADDRESS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/save_address/`;
            
            try {
                const response = await fetch(SAVE_ADDRESS_API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    },
                    body: JSON.stringify(this.form),
                });

                if (!response.ok){
                    throw new Error('Failed to save address');
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Address Saved',
                    text: 'Your delivery address has been successfully saved!',
                    confirmButtonText: 'OK'
                }).then(() => {
                    this.$router.push('/addresses');
                });
            } catch (error) {
                console.error('Error saving address:', error);
            }
        }
    },

    async onUpdate() {
        if (this.validateForm()) {
            const token = localStorage.getItem('token');
            const UPDATE_ADDRESS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/update_address/${this.address_id}/`;

            try {
              const response = await fetch(UPDATE_ADDRESS_API, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
                },
                body: JSON.stringify(this.form)
              });
              if (!response.ok) {
                throw new Error('Failed to update address');
              }
              Swal.fire({
                icon: 'success',
                title: 'Address Saved',
                text: 'Address updated successfully',
                confirmButtonText: 'OK'
            }).then(() => {
                this.$router.push('/addresses');
            });
            } catch (error) {
              console.error('Error updating address:', error);
            }
          }
    },

    // Initialize Google Maps Autocomplete
    initAutocomplete() {
      if (google && google.maps && google.maps.places && google.maps.places.Autocomplete) {
        const autocomplete = new google.maps.places.Autocomplete(
          document.getElementById('street_address'),
          { types: ['geocode'] }
        );
        autocomplete.addListener('place_changed', this.onPlaceSelected);
      } else {
        console.error('Google Maps Places library not loaded');
      }
    },

    onPlaceSelected() {
      const place = this.autocomplete.getPlace();
      if (!place.geometry) {
        console.error('No details available for the input: ' + place.name);
        return;
      }      
      // Populate the address fields with the selected place details
      this.form.street_address = place.formatted_address;
      this.form.city_town = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';
      this.form.suburb = place.address_components.find(component => component.types.includes('sublocality_level_1'))?.long_name || '';
      this.form.postal_code = place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '';
      this.form.province = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
    },

    onAddressInput() {
        if (this.form.street_address.length > 2) {
          if (window.google && window.google.maps) {
            const service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions(
              { input: this.form.street_address,
                componentRestrictions: { country: 'ZA' },
               },
              (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  this.suggestions = predictions;
                } else {
                  console.error('Error fetching address suggestions:', status);
                }
              }
            );
          }
        }
      },
    
      selectAddress(suggestion) {
        this.form.street_address = suggestion.description;
        this.suggestions = [];
    
        // Use Google Geocoder to get detailed information
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: suggestion.description }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                const result = results[0];
                // Get the address components
                const components = result.address_components;
    
                let streetAddress = '';
                for (let i = 0; i < components.length; i++) {
                    const component = components[i];
                    if (component.types.includes('street_number')) {
                        streetAddress = component.long_name; // Set street number
                    }
                    if (component.types.includes('route')) {
                        streetAddress += ' ' + component.long_name;
                    }
                }
                this.form.street_address = streetAddress;
    
                // Fill out the other fields based on the address components
                this.form.suburb = this.getComponent(components, 'sublocality_level_1');
                this.form.city_town = this.getComponent(components, 'locality');
                this.form.province = this.getProvinceFromComponent(components);
                this.form.postal_code = this.getComponent(components, 'postal_code');
            }
        });
    },

    getProvinceFromComponent(components) {
        const provinceComponent = components.find(comp => comp.types.includes('administrative_area_level_1'));
        if (provinceComponent) {
            const provinceName = provinceComponent.long_name;
            // Now, find the corresponding province in your provinces array
            const province = this.provinces.find(p => p.province_name === provinceName);
            return province ? province.id : ''; 
        }
        return '';
    },
    
    // Utility function to extract components based on the type
    getComponent(components, type) {
        const component = components.find(comp => comp.types.includes(type));
        return component ? component.long_name : '';
    },
    
    loadGoogleMapsAPI(apiKey) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
  },
};
