import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return { 
      videos: []
    };
  },
  methods:{

  async fetchVideos() {
    try {
      const token = localStorage.getItem('token');
      const FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_brand_videos/`;
      const response = await fetch(FETCH_VIDEOS_URL, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      const data = await response.json();
      this.videos = data;
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  },
  },
  mounted() {
    this.fetchVideos();
  },
}



