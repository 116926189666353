import View_brands from '@/views/Brand_Management/View_Brands/ViewBrands.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'

export default {
  name: 'ViewBrands',
  components: {
    View_brands,
    Home_navbar,
    Filter_product
  },

  data: () => ({ brands: [], allProducts: [], search_query: "" }),

    computed: {
      filteredProducts() {
        const query = this.search_query.toLowerCase();
        const search = new RegExp(query, 'i');
        return this.allProducts.filter((product) => {
          const match = (
            search.test(product.product.product_name) ||
            search.test(product.product.brand__brand_name) ||
            search.test(product.product.description) ||
            search.test(product.product.category__category_name)
          );
          return match;
        });
      },
      availableBrands() {
        return this.brands.filter(brand => brand.logo);
      }
    },
    mounted() {
      this.fetchBrandsLogos();
      this.fetchProducts();
    },
    methods: {
      update_search(event) {
        this.$emit('update:search_input', event.target.value);
        this.search_query = event.target.value;
      },
    filter_product_list(data) {
        this.allProducts = data;
        this.filteredProducts = data;
      },
      async fetchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
        try {
          const response = await fetch(FETCH_PRODUCT_URL, {
            headers: {
              'Content-Type': 'application/json'
            },
          });
          const data = await response.json();
          this.allProducts = data;
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      },
      async fetchBrandsLogos() {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_brands/`);
        try {
          const data = await response.json();
          this.brands = data;
        } catch (error) {
          console.error('fetchBrandsLogos Error:', error);
        }
      },
      handleImageError(event) {
        console.error(`Image loading error: ${event.target.src}`, event);
      },
    }
}