import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue';
import New_Collection from '@/views/Home/Products/New_collection/NewCollectionView.vue'

export default {
    name: 'NewCollectionView',
  components: {
    Home_navbar,
    Filter_product,
    New_Collection
},
data: () => ({ newProducts: [], days: 15 }),

  mounted() {
    this.fetchNewProducts()
  },
  methods: {
    async fetchNewProducts() {
        const FETCH_NEW_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/new_products/`;
      try {
        const response = await fetch(FETCH_NEW_PRODUCTS_URL,{
            headers: {
                'Content-Type': 'application/json'
              },
        })
        const data = await response.json()
        this.newProducts = data
      } catch (error) {
        console.error('Failed to fetch new products:', error)
      }
    },
    filter_product_list(filterData) {
      // Update days filter
      this.days = filterData.days
      this.fetchNewProducts()
    },
  }
}