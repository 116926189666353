
<div class="sidebar">
    <div class="logo-container">
      <img src="@/assets/images/Kyosk CI-11.png" alt="Kyosk Logo" class="logo" />
      <button @click="goToUpdateBrand" class="user-icon-btn">
        <img :src="logoUrl" alt="User Icon" class="user-icon" />
      </button>
       
    </div>
    <nav>
      <ul>
        <li class="nav-item ">
          <router-link to="/Admin_dashboard" exact-active-class="active">
            <button>
              <i class="fas fa-home icon"></i> Home
            </button>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/" exact-active-class="active">
            <button>
              <i class="icon fas fa-upload"></i> Brands
            </button>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/" exact-active-class="active">
            <button>
              <i class="icon fas fa-box"></i> Status Approval
            </button>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/" exact-active-class="active">
            <button>
              <i class="icon fas fa-shopping-cart"></i> User Manangement
            </button>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/" exact-active-class="active">
            <button>
              <i class="icon fas fa-chart-line"></i> Analytics
            </button>
          </router-link>
        </li>
      </ul>
    </nav>
      <div class="nav-item logout">
        <a href="#" @click.prevent="logout">
        <i class="icon fas fa-sign-out-alt"></i> Logout
      </a>
    </div>
  </div>
  