<div class="home-navbar ">
<Influencer_sidebar />
</div>

<div class=" app-content influencer-profile-sm ">
  <Influencer_navbar />
  <div class="card border-0 bg-transparent">

    <div class="profile-header rounded-12 position-relative">
      <div class="top-right-buttons">
        <button class="btn btn-light btn-sm me-2" @click="showEditProfileModal = true" data-toggle="tooltip" data-placement="bottom" title="Edit Profile">
          <i class='bx bx-pencil '></i> Profile
        </button>
        <button class="btn btn-outline-light btn-sm" data-toggle="tooltip" data-placement="bottom" title="Share Profile"><i class='bx bx-share-alt' ></i>  </button>

      </div>
      <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img w-auto"  id="profileImage">
      <i class="bx bxs-camera imge-edit" 
          data-toggle="tooltip" 
          data-placement="bottom" 
          title="Change Profile Picture" 
          @click="triggerFileInput" 
          id="uploadIcon">
      </i> 
      <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">

      <h2 class="profile-name">{{ form.first_name }} {{ form.last_name }}</h2>
      <h6 class="custom-margin-top ">{{ form.bio }}</h6>

    </div> 

    <ul class="nav nav-pills mb-3 justify-content-center mt-5" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-content-tab" data-bs-toggle="pill" data-bs-target="#pills-content" type="button" role="tab" aria-controls="pills-content" aria-selected="true"><i class='bx bx-video'></i> Content</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-pending-aproval-tab" data-bs-toggle="pill" data-bs-target="#pills-pending-aproval" type="button" role="tab" aria-controls="pills-pending-aproval" aria-selected="false"><i class='bx bx-message-square-dots'></i> Pending Approval</button>
      </li> 
    </ul>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">
        <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>
    
        <div v-else class="row">
          <div class="col-lg-3 col-md-6 col-sm-12 mb-4" v-for="video in videos" :key="video.id">
            <div class="view-video-lists" style="position: relative;">
              <!-- Display video with relative position -->
              <video :src="video.video_url" controls class="rounded-12 img-fluid h-250 object-fit-cover w-100">
                Your browser does not support the video tag.
              </video>
    
              <!-- Display associated product images on top of the video -->
              <div class="circle-images d-flex justify-content-center pb-85 ms-3">
                <div v-for="(image, index) in video.products" :key="index" class="text-center">
                  <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                    <input type="hidden" ref="image_id" :value="image.product.id">
              
                    <img 
                      v-if="image.product && image.product.image_url" 
                      :src="image.product.image_url" 
                      class="circle-image" 
                      @error="(event) => event.target.src = require('@/assets/images/img-error.png')" 
                      alt="Product Image"
                    >
                  </router-link>
                </div>
              </div>
            </div>
    
            <!-- Display video caption -->
            <div class="text-start mt-2 ms-1 text-sm">
              <h6 class="fw-bold">{{ video.caption }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    
      </div>
      <div class="tab-pane fade" id="pills-pending-aproval" role="tabpanel" aria-labelledby="pills-pending-aproval-tab">...
        
      </div> 
    </div>


<div v-if="showEditProfileModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="close-btn" @click="closeModal">&times;</span>
        <h5 class="modal-title">Profile</h5>
        <button type="button" class="close" @click="showEditProfileModal = false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center mb-3">           
          <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img-modal w-auto"  id="modalProfileImage">
          <i class="bx bxs-camera imge-edit-modal" 
              data-toggle="tooltip" 
              data-placement="bottom" 
              title="Change Profile Picture" 
              @click="triggerFileInput" 
              id="uploadIcon">
          </i> 
          <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">
        </div>
          <form @submit.prevent="updateUser">

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="first_name" class="text-label">First name:</label>
                <input type="text" class="form-control" v-model="form.first_name" id="first_name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="last_name" class="text-label">Last name:</label>
                <input type="text" class="form-control" v-model="form.last_name" id="last_name">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="text-label">Email address:</label>
                <input type="email" class="form-control" v-model="form.email" id="email">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone_number" class="text-label">Phone number:</label>
                <input type="text" class="form-control" v-model="form.phone_number" id="phone_number">
              </div>
            </div>
            <div class="form-group">
              <label for="bio" class="text-label">Bio:</label>
              <textarea class="form-control" v-model="form.bio" id="bio"></textarea>
          </div>
            
          </div>
        </form>
      </div>
      <div class="modal-footer">
          <button type="button" class="action-btn" @click="updateUser">Save changes</button>
      </div>
    </div>
  </div>
</div>
