<nav class="influencer-top-nav navbar navbar-expand-lg navbar-dark bg-dark z-2  rounded-12 mt-2">
    <div class="container-fluid">
        <i class="bx bx-menu fs-4 text-white ms-3 close- z " @click="toggleSidebar"></i>

        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item text-white">
                    <router-link to="/Customer_dashboard">
                        Home
                    </router-link> 
                </li> 
                <li class="nav-item text-white">
                    <router-link to="/Customer_profile">
                        Profile 
                    </router-link>                  
                </li> 
            </ul>
        </div>
        <div class="navbar-nav-mobile">
            <p class=" text-white">
                <router-link to="/Customer_dashboard">
                    Home
                </router-link> 
            </p> 
            <p class="nav-item ms-4 text-white">
                <router-link to="/Customer_profile">
                    Profile 
                </router-link>                  
            </p> 
        </div>
        <div class="d-flex align-items-center">
            <i class="bx bx-search nav-icon text-white me-3" id="search_icon"></i> 
            
                <a href="#" @click="logout">
                <i class="bx bx-log-in nav-icon text-white me-3"></i>  
            </a>
                      
        </div>
    </div>
</nav>
<div class="search-input bg-dark p-2 d-none" id="search_input" :value="search_input" @input="update_search">
    <div class="container-fluid">
        <input type="text" class="form-control" placeholder="Search...">
    </div>
</div> 

<div :class="{'open': sidebarOpen}" id="sidebar">
    <a href="#" class="back-btn" @click="toggleSidebar"><i class='bx bx-arrow-back'></i></a>
  
    <!-- Departments Loop -->
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
      <a href="#" class="sidebar-item department-name fs-6" @click.prevent="toggleSubSidebar(departmentIndex)">
        {{ department.department_name }}
      </a>
        
      <!-- Sub-Sidebar for Categories -->
      <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar" v-if="department.categories.length">
        <a href="#" class="back-btn" @click="toggleSubSidebar(departmentIndex)"><i class='bx bx-arrow-back'></i> Back to Main</a>
        
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
          <a href="#" 
             class="sidebar-item dropdown-toggle category-name" 
             data-bs-toggle="collapse" 
             :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex" 
             aria-expanded="false" 
             :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
            {{ category.category_name }}
          </a>
  
          <div class="collapse" :id="'categoryDropdown' + departmentIndex + categoryIndex" v-if="category.subcategories.length">
            <a v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
               :key="subcategoryIndex" 
               href="#" 
               class="dropdown-item">
              {{ subcategoryName.subcategory_name }}        
            </a>
          </div>
        </div>
      </div>
    </div>
</div>