import $ from 'jquery';

export default{
    name: 'ProductNavbarView',

    data(){
        return { departments: [], sidebarOpen: false, subSidebarOpen: [] };
    },

    created() {
        this.fetchCategories();
      },

    methods: {
        async fetchCategories() {
            const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;
      
            try {
              const response = await $.ajax({
                url: FETCH_CATEGORIES_URL,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              });
      
              this.departments = response;
              // Initialize subSidebarOpen with false values for each department
              this.subSidebarOpen = Array(this.departments.length).fill(false);
            } catch (error) {
              console.error(error);
            }
          },
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
          },
      
          toggleSubSidebar(departmentIndex) {
            // Toggle the open state of the clicked department's sub-sidebar
            this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
          }
    }

}