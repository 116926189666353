<div class="dashboard">
  <Sidebar />
  <div class="main-content">
      <Navbar />

      <div class="card" >
          <div id="profile" class="card-body">
              <h5>Brand Profile</h5>
              <div class="row">
                  <div class="col-md-12">
                      <form class="brand-form" @submit.prevent="updateBrandInfo">
                          <!-- Brand Name Input -->
                          <div class="form-group">
                              <label for="brand_name" class="text-label">Brand Name:</label>
                              <div>
                                  <input type="text" class="form-control form-control-sm" id="brand_name" v-model="form.brand_name" required />
                              </div>
                          </div>

                          <!-- Registration Number and VAT Number -->
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-6">
                                      <label for="registration_number" class="text-label">Registration Number:</label>
                                      <input type="text" class="form-control form-control-sm" id="registration_number" v-model="form.registration_number" required />
                                  </div>
                                  <div class="col-md-6">
                                      <label for="vat_number" class="text-label">VAT Number:</label>
                                      <input type="text" class="form-control form-control-sm" id="vat_number" v-model="form.vat_number" required />
                                  </div>
                              </div>
                          </div>

                          <!-- Upload Logo -->
                          <div class="form-group">
                              <div class="row align-items-center">
                                  <div class="col-md-4">
                                      <label for="logoInput" class="text-label">Upload Logo:</label>
                                  </div>
                                  <div class="col-md-8">
                                      <input type="file" id="logoInput" accept="image/*" class="file-input" @change="onFileChange" />
                                      <p v-if="form.fileName">{{ form.fileName }}</p>
                                  </div>
                              </div>
                          </div>

                          <h5>Brand Owner Information</h5>

                          <!-- Email and Phone Number -->
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-6">
                                      <label for="email" class="text-label">Email Address:</label>
                                      <input type="email" class="form-control form-control-sm" id="email" v-model="form.email" required />
                                  </div>
                                  <div class="col-md-6">
                                      <label for="phone_number" class="text-label">Phone Number:</label>
                                      <input type="tel" class="form-control form-control-sm" id="phone_number" v-model="form.phone_number" required />
                                  </div>
                              </div>
                          </div>

                          <!-- First Name and Last Name -->
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-md-6">
                                      <label for="owner_first_name" class="text-label">Brand Owner's First Name:</label>
                                      <input type="text" class="form-control form-control-sm" id="owner_first_name" v-model="form.owner_first_name" required />
                                  </div>
                                  <div class="col-md-6">
                                      <label for="owner_last_name" class="text-label">Brand Owner's Last Name:</label>
                                      <input type="text" class="form-control form-control-sm" id="owner_last_name" v-model="form.owner_last_name" required />
                                  </div>
                              </div>
                          </div>

                          <!-- Submit Button -->
                          <button type="submit" variant="primary" class="sub-button">Update</button>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
