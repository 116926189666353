import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Swal from 'sweetalert2';

export default {
  props: {
        influencerId: {
            type: String,
            required: true
        }
    },
  components: {
    Home_navbar,
  },
  data: () => ({

      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        bio: '',
        profileImage: ''
      },
      videos: [],
      showEditProfileModal: false,
      profileImage: '',
      hoveredVideo: null,
      visibleCards: 6,
      currentPosition: 0,
      showDetails: [],
      influencerId: null,
  }),
  created() {
    // Define influencerId here
    this.influencerId = this.$route.params.influencerId;
  },
  methods: {
    goBack() {
        this.$router.go(-1);
      },
    initializeShowDetails() {
      this.showDetails = new Array(this.videos.length).fill(false);
    },
    toggleDetails(index) {
      this.showDetails[index] = !this.showDetails[index];
    },
    toggleOverlay(index, isHovering) {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!this.showDetails[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    },
   
    scrollCards(direction) {
      const maxScroll = this.brand_videos.length - this.visibleCards;
      if (direction === 'right' && this.currentPosition < maxScroll) {
        this.currentPosition++;
      } else if (direction === 'left' && this.currentPosition > 0) {
        this.currentPosition--;
      }
    },
    async openEditProfileModal() {
      await this.fetchUserData();
      this.showEditProfileModal = true;
    },

    async fetchUserData() {
        const FETCH_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/public_user_profile/${this.influencerId}/`;
        try {
          const response = await fetch(FETCH_USER_URL, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
          });
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        localStorage.setItem('user_id', data.user_id);

        if (data.status === "success") {
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.email = data.email;
          this.form.phone_number = data.phone_number;
          this.form.bio = data.bio || '';  // Ensure the bio is set correctly
          this.profileImage = data.image || ''; 
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch user data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    

    async fetchVideos() {
        const FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/public_influencer_video_content/${this.influencerId}/`;
      try {
        const response = await fetch(FETCH_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }

        const data = await response.json();
        this.videos = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },

    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.getElementById('modalProfileImage');
          if (img) {
            img.src = e.target.result;
          }
        };
        reader.readAsDataURL(file);
        this.form.profileImage = file;
      }
    },
    

    saveProfile() {
      // Handle saving the profile information here
      this.showEditProfileModal = false;
    },
    closeModal() {
      this.showEditProfileModal = false;
    },
  },
  

  mounted() {
    this.fetchUserData();
    this.fetchVideos();
    this.handleClickOutside();    
  
    // jQuery event handlers
    
    this.initializeShowDetails();
  },
  beforeDestroy() {
    this.handleClickOutside();
  }
};
