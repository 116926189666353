/* global $ */
import EventBus from '@/eventBus.js';

export default {
  name: 'HomeNavbarView',

  props: {
    search_input: {
      required: false,
      type: String
    },
    currentRole: {
      type: String,
      default: 'customer', // Default role
    },
  },

  data() {
    return {
      selectedRole: 'customer',
      departments: [],
      sidebarOpen: false,
      subSidebarOpen: [], 
      selectedDepartmentIndex: null,
      cartQuantity: 0,
      cartTotal: 0, 
      isCartOpen: false,
      cartItems: JSON.parse(localStorage.getItem('cart')) || [],


    };
  },

  created() {
    this.fetchCategories();
    this.fetchCartDetails();
    
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    this.selectRole('customer');

    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });

  },

  beforeDestroy() {
    // Cleanup event listener
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.off('roleChanged');
  },

  methods: {
    toggleCart() {
      this.fetchCartDetails();
      this.isCartOpen = !this.isCartOpen; // Toggle the visibility of the cart dropdown
    },
    closeCart() {
      this.isCartOpen = false;
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.cartDropdown;
      const iconContainer = this.$refs.iconContainer;

      // Check if the click is outside the dropdown and icon container
      if (dropdown && !dropdown.contains(event.target) && !iconContainer.contains(event.target)) {
        this.closeCart();
      }
    },
  
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
  
    async fetchCartDetails() {
      const cartCookie = this.getCookie('cart');
      const cart = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];
    
      for (const item of cart) {
      
          const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
          const response = await fetch(GET_IMAGE_URL);
          
          if (!response.ok) {
            throw new Error('Failed to fetch image URL');
          }
    
          const imageData = await response.json();
          item.image = imageData;
      }
    
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    },    

    deleteFromCart(id, product_id) {
      // Get the cart from cookies
      const cart = JSON.parse(this.getCookie('cart')) || [];
      
      const updatedCart = cart.filter(item => item.id !== id || item.product_id !== product_id);
    
      this.setCookie('cart', JSON.stringify(updatedCart), 1);
    
      // Update the cartItems and fetch updated cart details
      this.cartItems = updatedCart;

      this.fetchCartDetails();
    
      // Emit the cart updated event
      EventBus.emit('cart-updated', updatedCart.length);
    },
    increaseQuantity(id, product_id) {
      // Find the cart item by its id and product_id
      const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
      if (item) {
        item.quantity++;
        this.updateCartInLocalStorage(id, product_id);
      }
    },
    
    decreaseQuantity(id, product_id) {
      const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
      if (item && item.quantity > 1) {
        item.quantity--;
        this.updateCartInLocalStorage(id, product_id); 
      }
    },
    
    updateCartInLocalStorage(id, product_id) {
      // Retrieve the cart from the cookie
      const cartCookie = this.getCookie('cart');
      const cart = cartCookie ? JSON.parse(cartCookie) : [];
    
      // Find and update the specific item by id and product_id
      const existingItem = cart.find(item => item.id === id && item.product_id === product_id);
      if (existingItem) {
        const updatedItem = this.cartItems.find(item => item.id === id && item.product_id === product_id);
        if (updatedItem) {
          existingItem.quantity = updatedItem.quantity;
        }
      }
    
      // Save the updated cart back to the cookie
      this.setCookie('cart', JSON.stringify(cart), 1);
      this.fetchCartDetails();
    },    
    
    goToLandingPage() {
      this.$router.push('/'); 
    },
    selectRole(role) {
      EventBus.emit('roleChanged', role);
      this.$emit('role-selected', role)
    },
    async fetchCategories() {
      const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await $.ajax({
          url: FETCH_CATEGORIES_URL,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.departments = response;
        // Initialize subSidebarOpen with false values for each department
        this.subSidebarOpen = Array(this.departments.length).fill(false);
      } catch (error) {
        console.error(error);
      }
    },

    update_search(event) {
      this.$emit('update:search_input', event.target.value);
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },

    toggleSubSidebar(departmentIndex) {
      // Toggle the open state of the clicked department's sub-sidebar
      this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
    }
  }
};
