<nav class="navbar navbar-dark bg-dark fixed-top">
  <div class="container-fluid">
    <button class="btn text-white" @click="goBack">
      <i class="fas fa-arrow-left"></i>
    </button>
    <div class="ms-auto">
      <div class="nav-icons me-3 d-flex align-items-center">
        <div class="icon-container me-3" @click="toggleCart" ref="iconContainer">
          <a href="#">
            <i class='bx bx-shopping-bag text-white' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        
        <div class="icon-container" data-dropdown>
          <a href="#"><i class='bx bx-user text-white'></i></a>
          <div class="dropdown-content"> 
            <router-link to="/register" class="">
              <span class="text-dark" >Sign Up</span>
            </router-link>
       
            <router-link to="/login" class=" ">
              <span class="text-dark">Login</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
    <div class="d-flex custom-container">
      <div class="card border-0 bg-transparent">
    
        <div class="profile-header rounded-12">
          <div class="top-right-buttons">
            <button class="btn btn-outline-light btn-sm" data-toggle="tooltip" data-placement="bottom" title="Share Profile"><i class='bx bx-share-alt' ></i>  </button>
    
          </div>
          <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img w-auto"  id="profileImage">
          <i class="bx bxs-camera imge-edit" 
              data-toggle="tooltip" 
              data-placement="bottom" 
              title="Change Profile Picture" 
              @click="triggerFileInput" 
              id="uploadIcon">
          </i> 
          <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">
    
          <h2 class="profile-name">{{ form.first_name }} {{ form.last_name }}</h2>
          <h6 class="custom-margin-top ">{{ form.bio }}</h6>
    
        </div> 
    
        <ul class="nav nav-pills mb-3 justify-content-center mt-5" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-content-tab" data-bs-toggle="pill" data-bs-target="#pills-content" type="button" role="tab" aria-controls="pills-content" aria-selected="true"><i class='bx bx-video'></i> Content</button>
          </li>
        </ul>
    
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">
            <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>
        
            <div v-else class="row">
              <div class="col-lg-3 col-md-6 col-sm-12 mb-4" v-for="video in videos" :key="video?.id">
                <div class="view-video-lists" style="position: relative;">
                  <video :src="video?.video_url" controls class="rounded-12 img-fluid h-250 object-fit-cover w-100">
                    Your browser does not support the video tag.
                  </video>
            
                  <div class="circle-images d-flex justify-content-center pb-85 ms-3">
                    <div v-for="(image, index) in video?.products || []" :key="index" class="text-center">
                      <router-link :to="{ name: 'product', params: { id: image?.product?.id || 0 }}">
                        <img 
                          :src="image?.product?.image_url || require('@/assets/images/img-error.png')" 
                          class="circle-image"
                          @error="(event) => event.target.src = require('@/assets/images/img-error.png')" 
                          alt="Product Image">
                      </router-link>
                    </div>
                  </div>
                </div>
            
                <div class="text-start mt-2 ms-1 text-sm">
                  <h6 class="fw-bold">{{ video?.caption || 'No Caption' }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        
          </div>
          <div class="tab-pane fade" id="pills-pending-aproval" role="tabpanel" aria-labelledby="pills-pending-aproval-tab">...
            
          </div> 
        </div>