import $ from 'jquery'; // Ensure jQuery is imported if used
import Swal from 'sweetalert2'; // Import SweetAlert2

export default {
  name: 'BrandView',
  data() {
    return {
      form: {
        registration_number: '',
        vat_number: '',
        email: '',
        phone_number: '',
        owner_first_name: '',
        owner_last_name: '',
        logo:''
      },
      errorMessage: '',
      successMessage: '',
      emailErrorMessage: '' 
    };
  },

  mounted() {
    this.fetchBrandNameFromServer();
  },

  methods: {
        onFileChange(event) {
          const file = event.target.files[0];
          if (file) {
            this.form.logo = file;
          }
        },
    async fetchBrandNameFromServer() {
      const token = localStorage.getItem('token');
      const FETCH_BRAND_NAME_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/brand/`;

      try {
        const response = await $.ajax({
          url: FETCH_BRAND_NAME_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'

          },
          dataType: 'json',
        });


        
        if (response.status === 'success') {
          localStorage.setItem('brand_name', response.brand_name);
          this.form.brand_name = response.brand_name;
          this.userId = response.user_id;
          this.brandId = response.brand_id;
        } else {
          console.error("Failed to fetch brand name from server:", response.message);
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch brand name from server.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    validateForm() {
      const name_pattern = /^[a-zA-Z]+$/;
      const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phone_pattern = /^[0-9]+$/; 

      if (!name_pattern.test(this.form.owner_first_name)) {
        this.errorMessage = 'First name should not contain numbers or special characters.';
        return false;
      }

      if (!name_pattern.test(this.form.owner_last_name)) {
        this.errorMessage = 'Last name should not contain numbers or special characters.';
        return false;
      }

      if (!email_pattern.test(this.form.email)) {
        this.emailErrorMessage = 'Invalid email format. Please enter a valid email address.';
        return false;
      }

      if (!phone_pattern.test(this.form.phone_number)) {
        this.errorMessage = 'Phone number should only contain numbers.';
        return false;
      }

      this.errorMessage = ''; 
      this.emailErrorMessage = '';

      return true;
    },
    
    async onSubmit() {
      if (!this.validateForm()) {
        Swal.fire({
          title: 'Validation Error',
          text: this.errorMessage || this.emailErrorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const formData = new FormData();
      formData.append('user_id', this.userId); // Use userId from API
      formData.append('brand_id', this.brandId); // Use brandId from API
      formData.append('registration_number', this.form.registration_number);
      formData.append('vat_number', this.form.vat_number);
      formData.append('email', this.form.email);
      formData.append('phone_number', this.form.phone_number);
      formData.append('owner_first_name', this.form.owner_first_name);
      formData.append('owner_last_name', this.form.owner_last_name);

      if (this.form.logo) {
        formData.append('logo', this.form.logo); // Add the file
      }

      try {

        const SAVE_BRAND_INFO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/brand_info/`;
        const token = localStorage.getItem('token');
        
        const response = await $.ajax({
          url: SAVE_BRAND_INFO_URL,
          method: 'POST',
          contentType: false, 
          processData: false, 
          headers: {
            'Authorization': `Token ${token}`
          },
          data: formData,
          dataType: 'json',
        });

        if (response.status === 'success') {
          Swal.fire({
            title: 'Success',
            text: response.message,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            localStorage.setItem('token', response.token);
            window.location.href = '/dashboard';
          });
          this.successMessage = response.message;
          this.errorMessage = '';
        } else {
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
          this.errorMessage = response.message;
          this.successMessage = '';
        }
      } catch (error) {
        console.error("Error during AJAX request:", error);
        let errorMessage = 'An error occurred during the submission.';
        if (error.responseJSON && error.responseJSON.message) {
          errorMessage = error.responseJSON.message;
        }
        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        this.errorMessage = errorMessage;
        this.successMessage = '';
      }
    },
  }
}
